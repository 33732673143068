import { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import DatePicker1 from "../../assets/datePiker";
import Marker from "../../assets/marker";
import { search } from "../mapApi/map";
import DatePicker from "./datePicker";
import carIcon from "../../assets/Icon-car.svg";
import busIcon from "../../assets/Icon-bus.svg";
import runningIcon from "../../assets/Icon-running.svg";
import shipIcon from "../../assets/Icon-ship.svg";
import trainIcon from "../../assets/Icon-train.svg";

export function TripInput({ placeholder, start, setStart, defValue }) {
  const [searchValue, setSearchValue] = useState(defValue?.name || "");
  const [searchData, setSearchData] = useState();

  useEffect(() => {
    setSearchValue(defValue?.name || "");
  }, [defValue]);

  useEffect(() => {
    if (searchValue?.length > 0) {
      search(searchValue)
        .then((res) => setSearchData(res))
        .catch((err) => console.log(err));
    } else {
      setSearchData({});
    }
  }, [searchValue]);

  return (
    <Box display={"flex"} alignItems={"center"} width="100%">
      <Autocomplete
        fullWidth
        disableClearable
        freeSolo
        getOptionLabel={(option) => option.name || ""}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.code}
              alignItems="center"
              display="flex"
            >
              <Typography color="#8E8E8E" fontSize="14px">
                {option?.name}
              </Typography>
              <Box height="fit-content" display="flex">
                <Box
                  my="2px"
                  height="12px"
                  bgcolor="#B4B4B4"
                  width="0.5px"
                  mx="4px"
                />
                <Typography color="#B4B4B4" fontSize="10px">
                  {option?.type === "attraction"
                    ? "جاذبه"
                    : option.type === "facility"
                    ? option.slogan || "تاسیسات"
                    : "مکان"}
                </Typography>
              </Box>
            </Box>
          );
        }}
        options={
          searchData?.result
            ? [
                ...searchData?.result?.destination,
                ...searchData?.result?.attraction,
                ...searchData?.result?.facility,
              ]
            : []
        }
        onChange={(e, data) => {
          setSearchValue(data.name);
          setStart(data);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            value={searchValue}
            variant="standard"
            placeholder={searchValue || placeholder}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            sx={{ mt: "5px", fontSize: "12px" }}
          />
        )}
      />
      <Box display={"flex"} mt={"10px"} mb={"-20px"} mr={"10px"}>
        <Marker />
      </Box>
    </Box>
  );
}

export default function DetailsMyTrip({
  setStart,
  setStartData,
  data,
  removeLayer,
}) {
  const [valueInput, setValueInput] = useState(data?.data.start_at || "");
  const [endValue, setEndValue] = useState(data?.data.end_at || "");

  useEffect(() => {
    setStartData((x) => {
      return { ...x, start_at: valueInput };
    });
  }, [valueInput]);

  useEffect(() => {
    setStartData((x) => {
      return { ...x, end_at: endValue };
    });
  }, [endValue]);

  const phone = useMediaQuery("(max-width:520px)");

  return (
    <Box
      width={phone ? "calc(99vw - 10px)" : "315px"}
      position="relative"
      mt={phone ? "3px" : "5px"}
      borderRadius={phone ? "0px" : "10px"}
      overflow="hidden"
      zIndex={1}
      bgcolor={"white"}
      boxShadow="0 3px 6px #00000025"
      alignItems={"center"}
      padding={"10px"}
    >
      <Box mb="10px">
        <TripInput
          defValue={data?.data?.start_place || undefined}
          start="true"
          placeholder={"شروع سفر"}
          setStart={(x) => {
            removeLayer();
            setStart(x);
            setStartData(
              endValue !== ""
                ? valueInput !== ""
                  ? {
                      start_place_id: x.code,
                      start_at: valueInput,
                      end_at: endValue,
                    }
                  : { start_place_id: x.code, end_at: endValue }
                : valueInput !== ""
                ? {
                    start_place_id: x.code,
                    start_at: valueInput,
                  }
                : { start_place_id: x.code }
            );
          }}
        />
      </Box>
      <Box display={"flex"} mt={"5px"} ml={"22px"}>
        <DatePicker
          name="start"
          input={true}
          setValueInput={setValueInput}
          valueInput={valueInput}
          defValue={data?.data.start_at}
          fullWidth
          maxDate={endValue}
        />
        <Box mr="10px" mt={"15px"} height="fit-content">
          <DatePicker1 color={"#c59358"} />
        </Box>
        <DatePicker
          name="end"
          placeholder={"تاریخ پایان"}
          input={true}
          setValueInput={setEndValue}
          valueInput={endValue}
          defValue={data?.data.end_at}
          fullWidth
          minDate={valueInput}
        />
      </Box>
      <Box
        display={"flex"}
        mt={"10px"}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Typography color="#b4b4b4" fontSize="14px">
          نوع سفر{" "}
        </Typography>
        <img src={runningIcon} alt="" />
        <img src={busIcon} alt="" />
        <img src={shipIcon} alt="" />
        <img src={trainIcon} alt="" />
        <img src={carIcon} alt="" />
      </Box>
    </Box>
  );
}
