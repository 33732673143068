import { useMediaQuery, Box, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import AccordionBox from "./accordionBox";
import { getPlaceArea } from "./mapApi/map";
import RadioGroupBox from "./radioGroupBox";

export default function CategoryFilter({
  display,
  filtersData,
  selectFilter,
  setSelectFilter,
  setResult,
  setIranMarker,
  setLoading,
  filterCategory,
  hidden,
  closing,
  setClosing,
}) {
  const phone = useMediaQuery("(max-width:520px)");
  const [categoryNumber, setCategoryNumber] = useState(false);
  const [number, setNumber] = useState(false);

  useEffect(() => {
    if (!closing) return;
    setNumber(false);
    setCategoryNumber(false);
    setClosing(false);
  }, [closing]);

  return (
    <Box
      display={hidden ? "none" : display}
      paddingTop={phone ? "15px" : "0px"}
      bgcolor="white"
      width={phone ? "100vw" : "325px"}
      borderRadius={phone ? "0px" : "10px"}
      mt={phone ? "3px" : "5px"}
      boxShadow="0 3px 6px #00000025"
    >
      <Box pt="5px" px="10px">
        {" "}
        <Typography color="#8E8E8E" fontSize="13px" mb="5px">
          یکی از دسته های زیر را انتخاب کنید
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => {
              if (selectFilter) {
                setLoading(true);
                getPlaceArea(selectFilter).then((res) => {
                  setResult(res?.result);
                  setIranMarker(res?.result);
                  setLoading(false);
                  setCategoryNumber(null);
                  setNumber(null);
                });
              } else {
                toast.warning("فیلتری انتخاب کنید");
              }
            }}
            sx={{
              color: "white",
              bgcolor: "#C59358",
              ":hover": { bgcolor: "#C59358c0" },
              borderRadius: "10px",
            }}
          >
            نمایش بر روی نقشه
          </Button>
        </Box>
      </Box>
      <AccordionBox
        accordionData={filterCategory.map((param) => {
          return {
            title: (
              <Typography width="100%" fontSize="14px">
                {param.tag.tag}
              </Typography>
            ),
            accordion: (
              <AccordionBox
                accordionData={param.body.map((param) => {
                  return {
                    title: (
                      <Typography width="100%" fontSize="14px">
                        {param.tag.tag}
                      </Typography>
                    ),
                    accordion: (
                      <RadioGroupBox
                        array={param.body.map((x) => {
                          return { name: x.tag.tag, value: x.tag.code };
                        })}
                        onChange={(value) => setSelectFilter(value)}
                        Value={selectFilter}
                      />
                    ),
                  };
                })}
                number={number}
                setNumber={setNumber}
                kepperSx={{
                  display: "flex",
                  gap: "4px",
                  flexDirection: "column",
                  px: "14px",
                }}
                boxSx={{
                  borderBottom: "1px solid #D9D9D9",
                  py: "6px",
                }}
              />
            ),
          };
        })}
        number={categoryNumber}
        setNumber={setCategoryNumber}
        kepperSx={{
          display: "flex",
          gap: "4px",
          flexDirection: "column",
          px: "14px",
        }}
        boxSx={{
          borderBottom: "1px solid #D9D9D9",
          py: "6px",
        }}
      />
      {/* <AccordionBox
        accordionData={filtersData.map((param, index) => {
          return {
            title: (
              <Typography width="100%" fontSize="14px">
                {param.tag.tag}
              </Typography>
            ),
            accordion: (
              <RadioGroupBox
                array={param.body.map((x) => {
                  return { name: x.tag.tag, value: x.tag.code };
                })}
                onChange={(value) => setSelectFilter(value)}
                Value={selectFilter}
              />
            ),
          };
        })}
        number={number}
        setNumber={setNumber}
        kepperSx={{
          display: "flex",
          gap: "4px",
          flexDirection: "column",
          px: "14px",
        }}
        boxSx={{
          borderBottom: "1px solid #D9D9D9",
          py: "6px",
        }}
      /> */}
    </Box>
  );
}
