import { Button, Popover, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Baggage from "../assets/baggage";
import GuideIcon from "../assets/guideIcon";
import Marker from "../assets/marker";
import Navigation from "../assets/navigation";
import Profile from "../assets/profile";
import Route from "../assets/route";
import Search from "../assets/search";
import { removeBoth } from "../MyTrip";
import { getTripToken, removeTripToken } from "./createTripDialog/api/token";
import { logout } from "./createTripDialog/api/trip";

export function ItemMenu({ text, color, onClick, children, width }) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <>
      <Box
        sx={{ cursor: "pointer" }}
        height={"44px"}
        width={width ? width : "55px"}
        boxShadow={"0px 3px 9px -5px"}
        borderRadius={"10px"}
        display={"flex"}
        flexDirection="column"
        alignItems={width ? "flex-start" : "center"}
        pr={width ? "10px" : "0px"}
        justifyContent={"center"}
        onClick={onClick}
      >
        {children}

        <Typography color={color} mb={"5px"} fontSize={"10px"}>
          {text}
        </Typography>
      </Box>
    </>
  );
}

export default function MenuPhone({
  onClickMyTravel,
  onClickPoints,
  onClickWay,
  onClickWeather,
  onClickLogin,
  setTripLength,
  setTripId,
  setShowMyTrip,
  onClickNavigation,
  map,
  showMyTrip,
  setShowResult,
  showResult,
  setClickLayer,
  info,
  setInfo,
}) {
  const [selected, setSelected] = useState(2);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = "simple-popover";

  useEffect(() => {
    setSelected(info ? 5 : undefined);
  }, [info]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: "-35px",
          ".MuiPopover-paper": {
            width: "55px !important",
            borderRadius: "0px",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Button
          onClick={() => {
            setTripLength(null);
            logout();
            handleClose();
            removeTripToken();
            setTripId(null);
            setShowMyTrip((x) => {
              if (x === true) {
                removeBoth(map);
              } else {
              }
              return false;
            });
          }}
          sx={{
            width: "100%",
            justifyContent: "right !important",
            color: "black",
          }}
        >
          خروج
        </Button>
      </Popover>

      <Box
        bgcolor={"white"}
        height={"60px"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent="space-evenly"
        gap={"6vw"}
      >
        {showMyTrip && (
          <Box
            mr="-5vw"
            width={"50%"}
            display={"flex"}
            justifyContent="space-between"
          >
            <ItemMenu
              onClick={() => {
                setSelected(8);
                setShowMyTrip(false);
              }}
              color={selected === 8 ? "#0089B7" : "#555A5D"}
              text={"جستجو"}
              children={
                <Search
                  color={selected === 8 ? "#0089B7" : "#555A5D"}
                  width={"20"}
                />
              }
            />
            <ItemMenu
              onClick={() => {
                setSelected(7);
                setShowResult();
              }}
              color={showResult ? "#0089B7" : "#555A5D"}
              text={"مکان ها"}
              children={
                <Marker
                  color={showResult ? "#0089B7" : "#555A5D"}
                  width={"20"}
                />
              }
            />
            <ItemMenu
              onClick={() => {
                setSelected(6);
                setClickLayer();
              }}
              color={selected === 6 ? "#0089B7" : "#555A5D"}
              text={"مسیریابی"}
              children={
                <Navigation
                  color={selected === 6 ? "#0089B7" : "#555A5D"}
                  width={"20"}
                />
              }
            />
          </Box>
        )}

        {!showMyTrip && (
          <>
            {!getTripToken() && (
              <ItemMenu
                width="150px"
                onClick={() => {
                  setSelected(4);
                  onClickLogin();
                }}
                color={selected === 4 ? "#C59358" : "#555A5D"}
                text={"ورود / ثبت نام"}
                children={
                  <Profile
                    color={selected === 4 ? "#C59358" : "#555A5D"}
                    width={"20"}
                  />
                }
              />
            )}
            {getTripToken() && (
              <ItemMenu
                onClick={() => {
                  setSelected(1);
                  onClickMyTravel();
                }}
                color={selected === 1 ? "#C59358" : "#555A5D"}
                text={"سفر من"}
                children={
                  <Baggage
                    color={selected === 1 ? "#C59358" : "#555A5D"}
                    width={"20"}
                  />
                }
              />
            )}
            {getTripToken() && (
              <ItemMenu
                onClick={(event) => {
                  setSelected(4);
                  handleClick(event);
                  // onClickLogOut();
                }}
                color={selected === 4 ? "#C59358" : "#555A5D"}
                text={"پروفایل"}
                children={
                  <Profile
                    color={selected === 4 ? "#C59358" : "#555A5D"}
                    width={"20"}
                  />
                }
              />
            )}
            <ItemMenu
              onClick={() => {
                setSelected(2);
                onClickPoints();
              }}
              color={selected === 2 ? "#C59358" : "#555A5D"}
              text={"نقاط"}
              children={
                <Search
                  color={selected === 2 ? "#C59358" : "#555A5D"}
                  width={"20"}
                />
              }
            />
            <ItemMenu
              onClick={() => {
                setSelected(3);
                onClickWay();
              }}
              color={selected === 3 ? "#C59358" : "#555A5D"}
              text={"مسیر"}
              children={
                <Route
                  color={selected === 3 ? "#C59358" : "#555A5D"}
                  width={"20"}
                />
              }
            />
          </>
        )}
        {/* <ItemMenu
          

          onClick={() => {
            setSelected(4);
            onClickWeather();
          }}
          color={selected === 4 ? "#C59358" : "#555A5D"}
          text={"آب و هوا"}
        /> */}
        <ItemMenu
          onClick={() => {
            setInfo(true);
          }}
          color={selected === 5 ? "#C59358" : "#555A5D"}
          text={"راهنما"}
          children={
            <GuideIcon
              color={selected === 5 ? "#C59358" : "#555A5D"}
              width={"20"}
            />
          }
        />
      </Box>
    </div>
  );
}
