import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Baggage from "../../assets/baggage";
import DatePicker from "../../assets/datePiker";
import Marker from "../../assets/marker";
import Navigation from "../../assets/navigation";
import Save from "../../assets/save";
import Search from "../../assets/search";
import Settings from "../../assets/settings";
import { VerticalLine } from "./optionResult";

export function IconBar({ text, children, onClick, disable, color }) {
  return (
    <Box
      onClick={disable ? () => {} : onClick}
      textAlign={"center"}
      sx={{ cursor: "pointer" }}
    >
      {children}
      <Typography
        marginTop={"-10px"}
        color={disable ? "#B4B4B4" : color ? color : "#0089B7"}
        fontSize={"10px"}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default function HeaderMyTrip({
  setShowMyTrip,
  save,
  removeLayer,
  removeMarkers,
  setLayer,
  removePopups,
  setTripId,
  setTripPlaceAdded,
  disablingWay,
  setShowDatePicker,
  setShowSetting,
  showSetting,
  showDatePicker,
}) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <Box
      width={phone ? "87vw" : "325px"}
      height="40px"
      right={phone ? "5vw" : ""}
      position="relative"
      mt={phone ? "0px" : "20px"}
      borderRadius={"10px"}
      overflow="hidden"
      zIndex={1}
      bgcolor={"white"}
      display={"flex"}
      boxShadow="0 3px 6px #00000025"
      alignItems={"center"}
      padding={"5px"}
    >
      <Box width={"10%"}>
        <Baggage color={"#C59358"} />
      </Box>
      <Box mr={"10px"} width={"100%"}>
        <Typography fontSize={"15px"}>{" سفر من "}</Typography>
        <Typography fontSize={"10px"} color={"#555A5D"}>
          {" سفر خود را بسازید "}
        </Typography>
      </Box>
      {!phone && (
        <Box width={"100%"} display={"flex"} justifyContent="space-evenly">
          <IconBar
            disable={disablingWay}
            onClick={setLayer}
            children={
              <Navigation
                width={"22"}
                color={disablingWay ? "#B4B4B4" : "#0089B7"}
              />
            }
            text={" مسیریابی "}
          />
          <VerticalLine height={"42px"} bgcolor="#B4B4B4" />
          <IconBar
            onClick={() => save()}
            children={
              <Box mb="5px">
                <Save width={"22"} color={"#0089B7"} />
              </Box>
            }
            text={" ذخیره "}
          />
          <VerticalLine height={"42px"} bgcolor="#B4B4B4" />
          <IconBar
            onClick={() => {
              removeMarkers();
              removeLayer();
              removePopups();
              setShowMyTrip(false);
              setTripId(null);
              setTripPlaceAdded(true);
            }}
            children={<Search width={"27"} color={"#0089B7"} />}
            text={" جستجو "}
          />
        </Box>
      )}
      {phone && (
        <Box width={"100%"} display={"flex"} justifyContent="space-evenly">
          <IconBar
            disable={disablingWay}
            onClick={setShowDatePicker}
            color={!showDatePicker ? "#B4B4B4" : "#0089B7"}
            children={
              <Box mb={"5px"}>
                <DatePicker
                  width={"22"}
                  color={!showDatePicker ? "#B4B4B4" : "#0089B7"}
                />
              </Box>
            }
            text={" اطلاعات سفر "}
          />
          <VerticalLine height={"42px"} bgcolor="#B4B4B4" />
          <IconBar
            onClick={setShowSetting}
            color={showSetting ? "#0089B7" : "#B4B4B4"}
            children={
              <Box mr={"-10px"}>
                <Settings
                  width={"28"}
                  color={showSetting ? "#0089B7" : "#B4B4B4"}
                />
              </Box>
            }
            text={" تنظیمات "}
          />
          <VerticalLine height={"42px"} bgcolor="#B4B4B4" />
          <IconBar
            onClick={() => save()}
            color={"#0089B7"}
            children={
              <Box mb="5px">
                <Save width={"22"} color={"#0089B7"} />
              </Box>
            }
            text={" ذخیره "}
          />
        </Box>
      )}
    </Box>
  );
}
