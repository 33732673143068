export default function Arrow({ color, width, setWay }) {
  return (
    <div onClick={() => setWay((x) => !x)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "16.607"}
        height={width ? width : "16.607"}
        viewBox={width ? `0 0 ${width} ${width}` : `0 0 16.607 16.607`}
        cursor="pointer"
      >
        <g
          id="Icon_ionic-ios-arrow-dropleft"
          data-name="Icon ionic-ios-arrow-dropleft"
          transform={`scale(${
            width ? Number(width) / 16.607 : 1
          }) translate(-3.375 -3.375)`}
          className="svg_transition"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M17.285,10.207a.773.773,0,0,0-1.09,0l-3.808,3.82a.77.77,0,0,0-.024,1.062l3.752,3.764a.769.769,0,1,0,1.09-1.086l-3.186-3.233L17.289,11.3A.77.77,0,0,0,17.285,10.207Z"
            transform="translate(-3.798 -2.856)"
            fill={color ? color : "#c59358"}
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M11.678,3.375a8.3,8.3,0,1,0,8.3,8.3A8.3,8.3,0,0,0,11.678,3.375Zm4.966,13.269A7.023,7.023,0,1,1,6.712,6.712a7.023,7.023,0,1,1,9.932,9.932Z"
            fill={color ? color : "#c59358"}
          />
        </g>
      </svg>
    </div>
  );
}
