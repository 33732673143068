import {
  Box,
  Typography,
  IconButton,
  Chip,
  Collapse,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

import AccIcon from "../assets/accIcon";
import filter from "../assets/filter.svg";
import AccordionBox from "./accordionBox";
import CheckBoxGroup from "./checkBoxGroup";
import { useEffect } from "react";
import SearchNearPoints from "./searchNearPoints";
import CheckboxButton from "./checkBox";
import checked from "../assets/checked.svg";

let deleteFilterIcon = false;

export default function AccordionFilter({
  handleChange,
  resultNumber,
  display,
  setBestPoints,
  setResultNumber,
  setResultAccept,
  filtersData,
  setFiltersData,
  filterCategory,
  setFilterCategory,
  filters,
  setFilters,
  distance,
  setDistance,
  result,
  hidden,
}) {
  const phone = useMediaQuery("(max-width:520px)");

  const [accordion, setAccordion] = useState(false);
  const [number, setNumber] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    deleteFilterIcon = false;
    if (!filters) return;
    if (!filters[0]) return;
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].length > 0) deleteFilterIcon = true;
    }
  }, [filters]);

  useEffect(() => {
    handleChange(filters);
    if (filterCategory && filtersData) {
      setFilterCategory(() => {
        let newCategory = [];
        let last = 0;
        filterCategory.map((param) => {
          let all = true;
          for (let i = last; i < last + param.body.length; i++) {
            if (filtersData[i].all !== true) all = false;
          }
          last = last + param.body.length;
          if (all === true) {
            newCategory.push({ ...param, filtered: true });
          } else {
            newCategory.push({ ...param, filtered: false });
          }
        });
        return newCategory;
      });
    }
  }, [filters]);

  return (
    <Box
      display={hidden ? "none" : display}
      paddingTop={phone ? "15px" : "0px"}
      bgcolor="white"
      width={phone ? "100vw" : "325px"}
      borderRadius={phone ? "0px" : "10px"}
      zIndex={fullScreen ? "100" : "3"}
      position={"sticky"}
      mt={phone ? (fullScreen ? "-110px" : "3px") : "5px"}
      boxShadow="0 3px 6px #00000025"
      sx={{ transition: "all 0.5s" }}
    >
      {/* {!phone && ( */}
      <Box
        display="flex"
        justifyContent="space-between"
        px="13px"
        py="5px"
        onClick={() => setAccordion(!accordion)}
      >
        <Box display="flex" gap="4px" alignItems="center">
          <img src={filter} alt="" />
          <Typography>دقیقتر شوید</Typography>
        </Box>
        {accordion && (
          <Box
            width="164px"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              sx={{ color: "#0066B1", fontSize: "10px", borderRadius: "10px" }}
              onClick={() => {
                setBestPoints(distance);
                setResultNumber(null);
                setResultAccept(false);
              }}
            >
              {"اعمال فیلتر"}
            </Button>
            {deleteFilterIcon && (
              <Button
                sx={{
                  color: "#E33156",
                  fontSize: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setFiltersData(
                    filtersData.map((x) => {
                      return { ...x, all: false };
                    })
                  );
                  setFilters(
                    filters.map(() => {
                      return [];
                    })
                  );
                  if (result) {
                    setBestPoints(
                      distance,
                      filters.map(() => {
                        return [];
                      })
                    );
                    setResultNumber(null);
                    setResultAccept(false);
                  }
                }}
              >
                حذف فیلتر
              </Button>
            )}
          </Box>
        )}
        <IconButton
          sx={{
            transform: accordion ? "rotate(90deg)" : "rotate(0deg)",
            transition: "all 0.4s",
            minWidth: "32px",
            minHeight: "32px",
          }}
        >
          <AccIcon
            onClick={() => setFullScreen(!fullScreen)}
            color={accordion && "#E33156"}
          />
        </IconButton>
      </Box>
      {/* )} */}
      <Box
        display="flex"
        flexWrap="wrap"
        gap="10px"
        p="10px"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          flexWrap="wrap"
          gap="10px"
        >
          <Typography fontSize={"12px"} color={"#8E8E8E"}>
            {"دسته ها:"}
          </Typography>
          <Box>
            {filterCategory &&
              filterCategory.map((param, index) => {
                return (
                  <Chip
                    onClick={() => {
                      setAccordion(true);
                      let last = 0;
                      for (let i = 0; i < index; i++) {
                        last = last + filterCategory[i].body.length;
                      }
                      for (let i = last; i < last + param.body.length; i++) {
                        setFiltersData((filtersData) =>
                          filtersData.map((x, index) => {
                            if (index === i) {
                              if (param.filtered) {
                                return { ...x, all: false };
                              } else {
                                return { ...x, all: true };
                              }
                            } else {
                              return x;
                            }
                          })
                        );
                        setFilters((filters) =>
                          filters.map((x, index) => {
                            if (index === i) {
                              if (!param.filtered) {
                                return filtersData[i].body.map(
                                  (x) => x.tag.code
                                );
                              } else {
                                return [];
                              }
                            } else {
                              return x;
                            }
                          })
                        );
                      }
                    }}
                    label={param.tag.tag}
                    key={index}
                    variant="outlined"
                    sx={{
                      mr: "5px",
                      borderRadius: "10px",
                      borderColor: param.filtered ? "#009BB9" : "#B4B4B4",
                      color: "#8E8E8E",
                      bgcolor: param.filtered ? "#009BB920" : "white",
                      ":hover": {
                        bgcolor: param.filtered
                          ? "#009BB920 !important"
                          : "white !important",
                      },
                    }}
                  ></Chip>
                );
              })}
          </Box>
        </Box>
        <SearchNearPoints
          setResultNumber={setResultNumber}
          // display={mode ? (showAcc ? "" : "none") : ""}
          setBestPoints={setBestPoints}
          setDistance={setDistance}
          distance={distance}
        />
        {/* {phone && (
          <Box>
            {!showIcon && (
              <Typography
                color={"#009BB9"}
                fontSize={"12px"}
                mt={"5px"}
                ml={"7px"}
                onClick={() => {
                  setAccordion(!accordion);
                  setShowIcon(!showIcon);
                }}
              >
                {" موارد بیشتر "}
              </Typography>
            )} 
            {showIcon && (
              <IconButton
                onClick={() => {
                  setAccordion(!accordion);
                  setShowIcon(!showIcon);
                }}
                sx={{
                  transform: accordion ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "all 0.4s",
                  minWidth: "32px",
                  minHeight: "32px",
                }}
              >
                <AccIcon color={accordion && "#E33156"} />
              </IconButton>
              )} 
          </Box>
        )} */}
      </Box>
      <Collapse in={accordion} timeout="auto" unmountOnExit>
        {filters && (
          <AccordionBox
            accordionData={filtersData.map((param, index) => {
              return {
                title: (
                  <Box width="100%">
                    <Box width="fit-content">
                      <CheckboxButton
                        readOnly
                        setValue={() => {}}
                        value={"sss"}
                        selectedValues={[]}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (filters[index].length === param.body.length) {
                              param.all = false;
                              setFilters(
                                filters.map((x, i) => {
                                  if (i === index) {
                                    return [];
                                  } else {
                                    return x;
                                  }
                                })
                              );
                            } else {
                              param.all = true;
                              setFilters(
                                filters.map((x, i) => {
                                  if (i === index) {
                                    return param.body
                                      .map((x) => {
                                        return {
                                          name: x.tag.tag,
                                          value: x.tag.code,
                                        };
                                      })
                                      .map((x) => x.value);
                                  } else {
                                    return x;
                                  }
                                })
                              );
                            }
                          }}
                        >
                          {filters[index].length === param.body.length ? (
                            <img
                              src={checked}
                              alt="check"
                              width={15}
                              height={15}
                            />
                          ) : (
                            <Box
                              border="1px solid #D9D9D9"
                              width="15px "
                              height="13px"
                            />
                          )}
                          <Typography width="100%" fontSize="14px">
                            {param.tag.tag}
                          </Typography>
                        </Box>
                      </CheckboxButton>
                    </Box>
                  </Box>
                ),
                accordion: (
                  <CheckBoxGroup
                    setAll={(e) => (param.all = e)}
                    array={param.body.map((x) => {
                      return { name: x.tag.tag, value: x.tag.code };
                    })}
                    onChange={(value) =>
                      setFilters(
                        filters.map((x, i) => {
                          if (i === index) {
                            return value;
                          } else {
                            return x;
                          }
                        })
                      )
                    }
                    Value={filters[index]}
                  />
                ),
              };
            })}
            number={number}
            setNumber={setNumber}
            kepperSx={{
              display: "flex",
              gap: "4px",
              flexDirection: "column",
              px: "14px",
            }}
            boxSx={{
              borderBottom: "1px solid #D9D9D9",
              py: "6px",
            }}
          />
        )}
      </Collapse>
      <Collapse in={!accordion} timeout="auto" unmountOnExit>
        <Box mx="14px" height="1px" bgcolor="#B4B4B4" mb="10px"></Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr="14px"
          pl="4px"
          mb="10px"
        >
          <Box display="flex" alignItems="center">
            {resultNumber > 1 && (
              <>
                <Typography color="#8E8E8E" fontSize="12px">
                  تعداد نتایج :&nbsp;
                </Typography>
                <Typography color="#E33156" fontSize="12px">
                  {resultNumber}
                </Typography>
              </>
            )}
          </Box>
          <Box>
            <Button
              sx={{ color: "#0066B1", fontSize: "10px", borderRadius: "10px" }}
              onClick={() => {
                setBestPoints(distance);
                setResultNumber(null);
                setResultAccept(false);
              }}
            >
              {"اعمال فیلتر"}
            </Button>
            {deleteFilterIcon && (
              <Button
                sx={{
                  color: "#E33156",
                  fontSize: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  setFiltersData(
                    filtersData.map((x) => {
                      return { ...x, all: false };
                    })
                  );
                  setFilters(
                    filters.map(() => {
                      return [];
                    })
                  );
                  if (result) {
                    setBestPoints(
                      distance,
                      filters.map(() => {
                        return [];
                      })
                    );
                    setResultNumber(null);
                    setResultAccept(false);
                  }
                }}
              >
                حذف فیلتر
              </Button>
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
