export default function Marker({ color }) {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.808"
      height="18.5"
      viewBox="0 0 12.808 18.5"
    >
      <path
        id="Icon_ionic-ios-pin"
        data-name="Icon ionic-ios-pin"
        d="M14.279,3.375a6.19,6.19,0,0,0-6.4,5.946c0,4.625,6.4,12.554,6.4,12.554s6.4-7.929,6.4-12.554A6.19,6.19,0,0,0,14.279,3.375Zm0,8.49a2.086,2.086,0,1,1,2.086-2.086A2.086,2.086,0,0,1,14.279,11.865Z"
        transform="translate(-7.875 -3.375)"
        fill={color ? color : "#c59358"}
      />
    </svg>
  );
}
