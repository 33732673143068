import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";

import { useEffect } from "react";
import { createTrip, getTrips } from "./api/trip";

export default function LastTrip({
  setTrip,
  setTripId,
  setShowMyTrip,
  tripPlace,
}) {
  const [data, setData] = useState();

  const d = false;
  useEffect(() => {
    getTrips()
      .then((res) => {
        if (res.data[0]) {
          setData(res.data);
        } else {
          setTrip(4);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [d]);

  return (
    <>
      <Typography color="#8E8E8E" fontSize="12" pr="8px">
        {!tripPlace[0]
          ? "سفر های پیشین شما"
          : "مکان انتخابی را به کدام سفر اضافه میکنید ؟"}
      </Typography>
      <Box
        px="20px"
        mt="10px"
        mb={"40px"}
        // maxHeight="115px"
        overflow="auto"
        sx={{
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {data?.map((param, index) => {
          return (
            <Box
              key={index}
              borderRadius="10px"
              boxShadow="0px 3px 6px#00000021"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my="10px"
              pr="5px"
            >
              <Box>{param.name}</Box>
              <Button
                sx={{ color: "#C59358" }}
                onClick={() => {
                  setTripId((x) => {
                    if (x === param.id) {
                      setShowMyTrip(true);
                      return param.id;
                    } else {
                      return param.id;
                    }
                  });
                  setTrip(null);
                }}
              >
                انتخاب
              </Button>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
