export const tokenKey = "map_token";
export const key = "p147e61e68fa364653b4710fdecd3d6cf55e40b00d";

export const getToken = () => {
  const token = localStorage.getItem(tokenKey);
  return token;
};

export const setToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};
