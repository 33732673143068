import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function SearchFilter({
  setSearch,
  searchData,
  setPlace,
  category,
  setCategory,
  hidden,
}) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <Box
      display={hidden ? "none" : ""}
      mt={phone ? "7px" : "5px"}
      marginRight={phone ? "-15px" : "0px"}
      bgcolor="white"
      zIndex={1}
      height={phone ? "40px" : "fit-content"}
      width={phone ? "90.5vw%" : "calc(325px - 36px)"}
      padding={phone ? "16px 16px 60px 20px" : "16px 16px 10px 20px"}
      borderRadius={phone ? "0px" : "10px"}
      boxShadow="0 3px 6px #00000025"
    >
      {!category && (
        <Autocomplete
          // disableClearable
          sx={
            phone
              ? {
                  position: "absolute",
                  width: "100vw",
                  zIndex: "3",
                }
              : {}
          }
          freeSolo
          options={
            searchData?.result
              ? [
                  ...searchData?.result?.destination,
                  ...searchData?.result?.attraction,
                  ...searchData?.result?.facility,
                ]
              : []
          }
          getOptionLabel={(option) => option.name || ""}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={option.code}
                alignItems="center"
                display="flex"
              >
                <Typography color="#8E8E8E" fontSize="14px">
                  {option?.name}
                </Typography>
                <Box height="fit-content" display="flex">
                  <Box
                    my="2px"
                    height="12px"
                    bgcolor="#B4B4B4"
                    width="0.5px"
                    mx="4px"
                  />
                  <Typography color="#B4B4B4" fontSize="10px">
                    {option?.type === "attraction"
                      ? "جاذبه"
                      : option.type === "facility"
                      ? option.slogan || "تاسیسات"
                      : "مکان"}
                  </Typography>
                </Box>
              </Box>
            );
          }}
          onChange={(props, option) => {
            if (option && typeof option !== "string") {
              setSearch(option.name);
              setPlace(option);
            }
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder="محل مورد نظر را وارد کنید"
              {...params}
            />
          )}
        />
      )}
      <Box
        display="flex"
        justifyContent="left"
        alignItems="center"
        mt={phone ? "50px" : "20px"}
      >
        <Typography fontSize="13px" ml="5px" color="#B4B4B4">
          نوع جستجو
        </Typography>
        <Button
          onClick={() => {
            setCategory(false);
          }}
          sx={{
            py: "0px",
            borderRadius: "7px",
            borderBottomLeftRadius: "0",
            borderTopLeftRadius: "0",
            color: category ? "#B4B4B4" : "white",
            fontSize: "12px",
            backgroundColor: category ? "white" : "#C59358",
            border: category ? "1px solid #B4B4B4" : "1px solid #C59358",
            height: "28px",
            borderLeft: "0",
            ":hover": {
              bgcolor: category ? "#00000025" : "#C59358d0",
            },
          }}
        >
          مکان
        </Button>
        <Button
          onClick={() => {
            setCategory(true);
          }}
          sx={{
            py: "0px",
            borderRadius: "7px",
            borderBottomRightRadius: "0",
            borderTopRightRadius: "0",
            color: category ? "white" : "#B4B4B4",
            fontSize: "12px",
            backgroundColor: category ? "#C59358" : "white",
            height: "28px",
            border: category ? "1px solid #C59358" : "1px solid #B4B4B4",
            borderRight: "0",
            ":hover": {
              bgcolor: category ? "#C59358d0" : "#00000025",
            },
          }}
        >
          دسته ها
        </Button>
      </Box>
    </Box>
  );
}
