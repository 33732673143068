import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, useMediaQuery } from "@mui/material";

import "swiper/css";
import "swiper/css/navigation";
import "./swiper.css";

import { Navigation } from "swiper/modules";

import Slide from "./slide";

import slides from "./slides";

export default function InfoModal() {
  const tablet = useMediaQuery("(max-width: 1050px)");
  const phone = useMediaQuery("(max-width: 550px)");

  return (
    <Box bgcolor="white">
      <Swiper navigation={true} modules={[Navigation]} slidesPerView={1}>
        {slides(phone).map((x, i) => {
          return (
            <SwiperSlide key={i}>
              <Slide {...x} tablet={tablet} phone={phone} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
