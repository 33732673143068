import { Box, Popover, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import DatePickerIcon from "../../assets/datePiker";
import MoreIcon from "../../assets/moreIcon";
import Search from "../../assets/search";
import DatePicker from "./datePicker";
import { ImageBox } from "./result";
import { removePlace } from "../createTripDialog/api/trip";
import { useEffect } from "react";
import { toast } from "react-toastify";
import time from "../../assets/time.svg";
import check from "../../assets/check.svg";

export function VerticalLine({ height, bgcolor }) {
  return (
    <Box
      width={"0.25px"}
      height={height ? height : "25px"}
      mt={"-3px"}
      bgcolor={bgcolor ? bgcolor : "#B4B4B4"}
    ></Box>
  );
}

export function MenuItem({ item, color, onClick }) {
  return (
    <>
      <Typography
        sx={{ cursor: "pointer" }}
        color={color ? color : "#555A5D"}
        fontSize={"12px"}
        p={"5px 5px"}
        onClick={onClick}
      >
        {item}
      </Typography>
      <Box width={"95%"} height="1px" bgcolor={"#DEDEDE"}></Box>
    </>
  );
}

export default function OptionResult({
  index,
  onClick,
  onMinuteChange,
  param,
  deleteOption,
  setAt,
  minDate,
  maxDate,
  layer = true,
}) {
  const [showAcc, setShowAcc] = useState(false);
  const [valueInput, setValueInput] = useState(param.at ? param.at : "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openVisit, setOpenVisit] = useState(false);
  const [minute, setMinute] = useState(param.duration ? param.duration : 0);

  useEffect(() => {
    setValueInput(param.at ? param.at : "");
  }, [param]);

  useEffect(() => {
    if (!minute) return;
    if (Number(minute)) {
      console.log(Number(minute));
      onMinuteChange(param.id, minute);
    } else {
      toast.warning("مدت بازدید را به عدد وارد کنید");
    }
  }, [minute]);

  useEffect(() => {
    if (valueInput === "") return;
    setAt(param.id, valueInput);
  }, [valueInput]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        transition: "1.5s all",
        height: "fit-content",
        width: "315px",
        bgcolor: "white",
      }}
      padding={"10px"}
    >
      <Box
        height={"fit-content"}
        sx={{ transition: "1.5s all" }}
        alignItems={"center"}
        display={showAcc ? "none" : ""}
      >
        <Box
          alignItems={"center"}
          justifyContent={"space-between"}
          display={"flex"}
        >
          <ImageBox param={param.place} />
          <Box display={"flex"} gap="5px">
            <Typography
              bgcolor={"#B4B4B4"}
              width="20px"
              height={"20px"}
              borderRadius="50%"
              textAlign={"center"}
            >
              {index + 1}
            </Typography>
            {layer && (
              <>
                <VerticalLine />
                <Box
                  sx={{ cursor: "pointer" }}
                  alignItems={"center"}
                  display="flex"
                  aria-describedby={id}
                  onClick={handleClick}
                  px="5px"
                >
                  <MoreIcon />
                </Box>
              </>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  width: "141px",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px -3px",
                },
              }}
            >
              {/* <MenuItem
                onClick={() => {
                  onClick(index, "first");
                  handleClose();
                }}
                item={"انتقال به اول لیست"}
              />
              <MenuItem
                onClick={() => {
                  onClick(index, "last");
                  handleClose();
                }}
                item={"انتقال به آخر لیست"}
              /> */}
              <MenuItem
                onClick={() => {
                  removePlace(param.id)
                    .then((res) => {
                      res.status === "ok" && deleteOption(param.id);
                      toast.success("مکان شما حذف گردید");
                    })
                    .catch(() => {
                      toast.error(
                        "حذف مکان با مشکل مواجه شد لطفا بعدا دوباره تلاش کنید"
                      );
                    });
                }}
                color={"#E33156"}
                item={"حذف از سفر"}
              />
              <MenuItem onClick={handleClose} item={"بستن منو"} />
            </Popover>
          </Box>
        </Box>
        <Box
          // width={openVisit ? "80%" : "65%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={"10px"}
          display={"flex"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <DatePickerIcon color={"#8E8E8E"} width={"15"} />
            {/* <Typography mr={"3px"} fontSize={"10px"} color={"#8E8E8E"}>
              {"تاریخ بازدید"}
            </Typography> */}
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              defValue={param.at ? param.at : ""}
              setValueInput={setValueInput}
              valueInput={valueInput}
              label={true}
            />
          </Box>
          {openVisit ? (
            <>
              <Box mr="7px">
                <img src={time} alt="" />
              </Box>
              <Box
                display="flex"
                width="180px !important"
                position="relative"
                height="30px"
              >
                <TextField
                  autoFocus
                  sx={{
                    width: "100px !important",
                    pr: "10px",
                    // ml: "7px",
                    position: "absolute",
                    zIndex: "2",
                  }}
                  variant="standard"
                  placeholder="دقیقه"
                  name="date"
                  onBlur={() => {
                    setOpenVisit(false);
                    setMinute(0);
                  }}
                  onChange={(e) => {
                    setMinute(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      setOpenVisit(false);
                    }
                  }}
                />
                <Box
                  top="0"
                  position="absolute"
                  left="10px"
                  sx={{ cursor: "pointer" }}
                  zIndex={5}
                  onClick={() => {
                    setOpenVisit(false);
                  }}
                >
                  <img src={check} alt="" />
                </Box>
              </Box>
            </>
          ) : (
            <Typography
              fontSize={"14px"}
              color={"#8E8E8E"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenVisit(true);
              }}
            >
              {minute ? `${minute} دقیقه` : "مدت بازدید"}
            </Typography>
          )}
          <Box display={"flex"} alignItems={"center"}>
            <Search color={"#8E8E8E"} width={"15"} />
            <Typography
              mr={"2px"}
              fontSize={"14px"}
              color={"#8E8E8E"}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                console.log("namad")
              }}
            >
              {"مشاهده"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
