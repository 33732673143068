import { useState } from "react";

import { Box, Button, Popover, Typography, useMediaQuery } from "@mui/material";

import { getTripToken, removeTripToken } from "./createTripDialog/api/token";
import { logout } from "./createTripDialog/api/trip";

import Baggage from "../assets/baggage";
import Profile from "../assets/profile";
import logo from "../assets/logo.png";
import { removeBoth } from "../MyTrip";
import AccIcon from "../assets/accIcon";

export default function Header({
  setTrip,
  onClick,
  tripLength,
  setShowMyTrip,
  map,
  setTripId,
  setTripLength,
}) {
  const phone = useMediaQuery("(max-width:520px)");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      width="100%"
      height="55px"
      top="0"
      zIndex={2}
      bgcolor="white"
      position="fixed"
      display="flex"
      boxShadow="0 3px 6px #00000025"
    >
      <Box
        mr={phone ? "5px" : "35px"}
        width="140px"
        height={phone ? "55px" : "100px"}
        borderRadius={phone ? "0px" : "0 0 30px 30px"}
        bgcolor="white"
        boxShadow="0px 3px 6px #00000024"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img style={{ height: phone ? "60px" : "" }} src={logo} alt="logo" />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" flexDirection="column" px="5px">
          <Typography fontSize="15px" color="#C59358">
            سفر ساز
          </Typography>
          <Typography fontSize="12px" color="#555A5D">
            سامانه برنامه ریزی سفر
          </Typography>
        </Box>
        {!phone && (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button
                onClick={() => setTrip(getTripToken() ? 3 : 1)}
                sx={{
                  color: "#555A5D",
                  fontSize: "12px",
                  backgroundColor: "#C59358",
                  borderRadius: "10px",
                  borderTopLeftRadius: getTripToken() ? "0" : "auto",
                  borderBottomLeftRadius: getTripToken() ? "0" : "auto",
                  ":hover": {
                    bgcolor: "#C59358d0",
                  },
                }}
                position="relative"
                variant="contained"
              >
                {/* {tripLength && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      fontFamily: "Roboto",
                      position: "absolute",
                      fontSize: "10px",
                      bgcolor: "#E33156",
                      ":hover": { bgcolor: "#E33156d0" },
                      right: "-12px",
                      p: "0",
                      width: "25px",
                      height: "25px",
                      color: "white",
                      borderRadius: "50%",
                    }}
                  >
                    {tripLength}
                  </Box>
                )} */}
                <Box display="flex" gap="20px" alignItems="center">
                  {getTripToken() ? <Baggage /> : <Profile />}
                  <Typography color="#ffffff">
                    {getTripToken() ? "سفر من" : "ورود - ثبت نام"}
                  </Typography>
                </Box>
              </Button>
              {getTripToken() && (
                <Button
                  variant="contained"
                  onClick={handleClick}
                  sx={{
                    color: "#555A5D",
                    fontSize: "12px",
                    backgroundColor: "#C59358",
                    borderRadius: "10px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    height: "36px",
                    mr: "3px",
                    ":hover": {
                      bgcolor: "#C59358d0",
                    },
                  }}
                >
                  <Profile />
                  <Box
                    mr="15px"
                    sx={{
                      transform: Boolean(anchorEl)
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "all 0.2s ease",
                    }}
                  >
                    <AccIcon color="white" />
                  </Box>
                </Button>
              )}
            </Box>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={{
                ".MuiPopover-paper": {
                  width: "194px !important",
                  borderRadius: "0px",
                  borderBottomRightRadius: "10px",
                  borderBottomLeftRadius: "10px",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Button
                onClick={() => {
                  setTripLength(null);
                  logout();
                  handleClose();
                  removeTripToken();
                  setTripId(null);
                  setShowMyTrip((x) => {
                    if (x === true) {
                      removeBoth(map);
                    } else {
                    }
                    return false;
                  });
                }}
                sx={{
                  width: "100%",
                  justifyContent: "right !important",
                  color: "black",
                }}
              >
                خروج
              </Button>
            </Popover>
            <Button
              onClick={onClick}
              sx={{ color: "#555A5D", fontSize: "12px" }}
            >
              بازگشت به وب سایت
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
