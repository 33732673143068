import { Box, Typography } from "@mui/material";

export default function TextWithTitel({ title, children, phone }) {
  return (
    <Box py="5px">
      {title && (
        <Typography
          textAlign="center"
          display="inline"
          variant="h6"
          fontSize={phone ? "18px" : ""}
        >
          {title} :{" "}
        </Typography>
      )}
      <Typography
        fontSize={phone ? "12px" : ""}
        textAlign="center"
        display="inline"
      >
        {children}
      </Typography>
    </Box>
  );
}
