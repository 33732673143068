import { useState } from "react";

import { Button, TextField, Typography } from "@mui/material";

import { useEffect } from "react";
import { createTrip } from "./api/trip";

export default function CreateTrip({ setTripId , setTrip }) {
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  let two = false;
  useEffect(() => {
    if (name[1]) {
      two = true;
    }
    if (!two) return;
    if (name.length < 2) {
      setError(true);
    } else {
      setError(false);
    }
  }, [name]);

  return (
    <>
      <Typography color="#8E8E8E" fontSize="12" pr="8px">
        لطفا نام سفر خود را وارد نمایید
      </Typography>
      <TextField
        error={error}
        onChange={(event) => setName(event.target.value)}
        sx={{ width: "296px !important", pr: "14px" }}
        variant="standard"
        placeholder="نام"
      />
      <Button
        disabled={!name[1]}
        onClick={() => {
          createTrip({ name: name })
            .then((res) => {
              if (res.id) {
                setTripId(res.id)
                setTrip(null)
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }}
        variant="contained"
        sx={{
          bgcolor: "#C59358",
          color: "white",
          fontSize: "12px",
          position: "absolute",
          bottom: "25px",
          left: "20px",
          borderRadius: "10px",
          ":hover": {
            bgcolor: "#C59358d6",
          },
        }}
      >
        ایجاد
      </Button>
    </>
  );
}
