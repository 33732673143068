import { Skeleton, useMediaQuery } from "@mui/material";
import { useState } from "react";

export default function ResultPicture({ param, logo }) {
  const [src, setSrc] = useState(false);

  const phone = useMediaQuery("(max-width:520px)");

  return (
    <>
      {src ? (
        <Skeleton
          width={phone ? "60px" : "40px"}
          height={phone ? "60px" : "40px"}
          animation="wave"
        />
      ) : (
        // <img
        //   width={phone ? "60px" : "40px"}
        //   src={logo}
        //   alt={param.slogan ? param.slogan : param.typeName}
        // />
        <div>
          <img
            width={phone ? "60px" : "40px"}
            src={param.pic ? param.pic : logo}
            alt={param.slogan ? param.slogan : param.typeName}
            onError={() => setSrc(true)}
          />
        </div>
      )}
    </>
  );
}
