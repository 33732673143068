import React from "react";

export default function DatePicker({ color, width }) {
  let height = width ? width * (14.233 / 12.81) : 14.233;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "12.81"}
      height={width ? `${height}` : "14.233"}
      viewBox={width ? `0 0 ${width} ${height}` : `0 0 12.81 14.233`}
      className="svg_transition"
    >
      <path
        id="Icon_material-date-range"
        data-name="Icon material-date-range"
        d="M8.77,9.4H7.347v1.423H8.77Zm2.847,0H10.193v1.423h1.423Zm2.847,0H13.04v1.423h1.423Zm1.423-4.982h-.712V3H13.752V4.423H8.058V3H6.635V4.423H5.923A1.417,1.417,0,0,0,4.507,5.847L4.5,15.81a1.423,1.423,0,0,0,1.423,1.423h9.963A1.428,1.428,0,0,0,17.31,15.81V5.847A1.428,1.428,0,0,0,15.887,4.423Zm0,11.387H5.923V7.982h9.963Z"
        transform={`scale(${width ? Number(width) / 12.81 : 1},${
          height ? Number(height) / 14.233 : 1
        }) translate(-4.5 -3)`}
        fill={color ? color : "#e33156"}
      />
    </svg>
  );
}

{
  /* <svg width="12.81" height="14.233" viewBox="0 0 12.81 14.233">
  <path
    id="Icon_material-date-range"
    data-name="Icon material-date-range"
    d="M8.77,9.4H7.347v1.423H8.77Zm2.847,0H10.193v1.423h1.423Zm2.847,0H13.04v1.423h1.423Zm1.423-4.982h-.712V3H13.752V4.423H8.058V3H6.635V4.423H5.923A1.417,1.417,0,0,0,4.507,5.847L4.5,15.81a1.423,1.423,0,0,0,1.423,1.423h9.963A1.428,1.428,0,0,0,17.31,15.81V5.847A1.428,1.428,0,0,0,15.887,4.423Zm0,11.387H5.923V7.982h9.963Z"
    transform="translate(-4.5 -3)"
    fill="#c59358"
  />
</svg>; */
}
