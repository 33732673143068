import { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { getTripToken } from "./api/token";

import Baggage from "../../assets/baggage.svg";
import Delete from "../../assets/delete";
import CreateTrip from "./createTrip";
import GetPhone from "./getPhone";
import LastTrip from "./lastTrips";
import Verification from "./verification";
import { useEffect } from "react";

export default function TripDialog({
  trip,
  setTrip,
  setTripId,
  tripPlace,
  setShowMyTrip,
}) {
  const [phone, setPhone] = useState();
  const [reminder, setReminder] = useState();

  const phoneSize = useMediaQuery("(max-width:520px)");

  return (
    <Dialog
      open={Boolean(trip)}
      onClose={() => setTrip(null)}
      fullWidth
      maxWidth="md"
      // width="100vw"
      sx={{ "& div div": { borderRadius: "10px" } }}
    >
      <Box minHeight="225px" position="relative">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box p="16.5px 14.5px 17.5px 9.5px">
              <img src={Baggage} width="18px" alt="baggage" />
            </Box>
            <Box>
              <Typography color="#555A5D" fontSize="15" fontWeight="Bold">
                سفر من
              </Typography>
              <Typography color="#555A5D" fontSize="10">
                سفر خود را بسازید
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" ml="14px" mt="14px">
            {trip === 3 && (
              <Button
                sx={{
                  position: phoneSize ? "absolute" : "inherit",
                  bottom: "5px",
                  right: "0px",
                  width: "100%",
                  bgcolor: "#C59358",
                  color: "white",
                  fontSize: "12px",
                  ml: "25px",
                  overflow: "hidden",
                  borderRadius: "10px",
                  ":hover": {
                    bgcolor: "#C59358d6",
                  },
                }}
                onClick={() => setTrip(4)}
              >
                ایجاد سفر جدید
              </Button>
            )}
            <IconButton
              sx={{ alignSelf: "flex-start" }}
              onClick={() => setTrip(null)}
            >
              <Delete width="14" color="black" />
            </IconButton>
          </Box>
        </Box>
        {trip === 1 && (
          <GetPhone
            setMainPhone={setPhone}
            setTrip={setTrip}
            setReminder={setReminder}
          />
        )}
        {trip === 2 && (
          <Verification
            phone={phone}
            setTrip={setTrip}
            reminder={reminder}
            setReminder={setReminder}
          />
        )}
        {trip === 3 && (
          <LastTrip
            setTrip={setTrip}
            setTripId={setTripId}
            tripPlace={tripPlace}
            setShowMyTrip={setShowMyTrip}
          />
        )}
        {trip === 4 && (
          <CreateTrip
            setTrip={setTrip}
            setTripId={setTripId}
            setShowMyTrip={setShowMyTrip}
          />
        )}
      </Box>
    </Dialog>
  );
}
