import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { search } from "./mapApi/map";

import plusIcon from "../assets/plus.svg";
import grayPlusIcon from "../assets/grayPlus.svg";
import deleteIcon from "../assets/delete.svg";
import { useEffect, useState } from "react";

export default function FieldArrayBox({
  object,
  index,
  setDestinations,
  destinations,
  ref1,
  ref2,
  ref3,
  ref4,
  ref5,
  ref6,
  ref7,
  ref8,
  ref9,
  ref10,
  ref11,
  ref12,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState();
  const phone = useMediaQuery("(max-width:520px)");

  useEffect(() => {
    if (searchValue?.length > 0) {
      search(searchValue)
        .then((res) => setSearchData(res))
        .catch((err) => console.log(err));
    } else {
      setSearchData({});
    }
  }, [searchValue]);

  return (
    <Box display="flex" alignItems="flex-end">
      <Autocomplete
        tabIndex={index === 0 ? index + 1 : 2 * index}
        disableClearable
        freeSolo
        getOptionLabel={(option) => option.name || ""}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.code}
              alignItems="center"
              display="flex"
            >
              <Typography color="#8E8E8E" fontSize="14px">
                {option?.name}
              </Typography>
              <Box height="fit-content" display="flex">
                <Box
                  my="2px"
                  height="12px"
                  bgcolor="#B4B4B4"
                  width="0.5px"
                  mx="4px"
                />
                <Typography color="#B4B4B4" fontSize="10px">
                  {option?.type === "attraction"
                    ? "جاذبه"
                    : option.type === "facility"
                    ? option.slogan || "تاسیسات"
                    : "مکان"}
                </Typography>
              </Box>
            </Box>
          );
        }}
        options={
          searchData?.result
            ? [
                ...searchData?.result?.destination,
                ...searchData?.result?.attraction,
                ...searchData?.result?.facility,
              ]
            : []
        }
        onChange={async (props, option) => {
          if (option && typeof option !== "string") {
            setSearchValue(option.name);
            await setDestinations((des) =>
              des.map((param, i) => {
                if (index === i) {
                  return {
                    key: param.key,
                    ...option,
                    color:
                      param.color ||
                      `rgb(${Math.random() * 150}, ${Math.random() * 150}, ${
                        Math.random() * 150
                      })`,
                  };
                } else {
                  return param;
                }
              })
            );
            await (index === 0
              ? ref2.current?.focus()
              : index === 1
              ? ref3.current?.focus()
              : index === 2
              ? ref5.current?.focus()
              : index === 3
              ? ref7.current?.focus()
              : ref9.current?.focus());
          }
        }}
        renderInput={(params) => (
          <TextField
            inputRef={
              index === 0
                ? ref1
                : index === 1
                ? ref2
                : index === 2
                ? ref4
                : index === 3
                ? ref6
                : index === 4
                ? ref8
                : ref10
            }
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            sx={{ width: phone ? "85vw" : "255px !important" }}
            variant="standard"
            placeholder={
              index === 0 ? "مبدا" : index === 1 ? "مقصد" : "مقصد بعدی"
            }
            {...params}
          />
        )}
      />
      {index !== 0 && index < 5 && (
        <IconButton
          ref={
            index === 1
              ? ref3
              : index === 2
              ? ref5
              : index === 3
              ? ref7
              : index === 4
              ? ref9
              : ref11
          }
          onClick={async () => {
            if (
              Boolean(
                index === destinations?.length - 1 &&
                  (!destinations[destinations?.length - 1].lat ||
                    !destinations[destinations?.length - 2].lat)
              )
            )
              return;
            if (index === destinations?.length - 1) {
              if (destinations[destinations?.length - 1].lat)
                await setDestinations((x) => [
                  ...x,
                  {
                    key: object.key + 1,
                    color: `rgb(${Math.random() * 150}, ${
                      Math.random() * 150
                    }, ${Math.random() * 150})`,
                  },
                ]);
              await (index === 1
                ? ref4.current?.focus()
                : index === 2
                ? ref6.current?.focus()
                : index === 3
                ? ref8.current?.focus()
                : index === 4
                ? ref10.current?.focus()
                : ref12.current?.focus());
            } else {
              setDestinations((x) => x.filter((a) => a.key !== object.key));
            }
            // document.getElementById(`tab${2 * index + 2}`).focus();
          }}
          sx={{ mr: "5px" }}
        >
          <img
            src={
              index === destinations.length - 1
                ? !destinations[destinations?.length - 1].lat ||
                  !destinations[destinations?.length - 2].lat
                  ? grayPlusIcon
                  : plusIcon
                : deleteIcon
            }
            alt=""
          />
        </IconButton>
      )}
    </Box>
  );
}
