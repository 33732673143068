import { Box, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState, memo } from "react";
import AccordionFilter from "./component/accordionFilter";
import CategoryFilter from "./component/categoryFilter";
import FieldArrayFilter from "./component/fieldArrayFilters";
import FirstFilter from "./component/firstFilter";
import { getFilters } from "./component/mapApi/map";
import ResultBox from "./component/resultBox";
import SearchNearPoints from "./component/searchNearPoints";
import SearchFilter from "./component/serachFilter";
import ShowNab from "./component/showNab";
import ShowResult from "./component/showResult";

let pointMarkers = [];

function MapBoxFilter({
  removePointMarks,
  setResult,
  lastWayResult,
  setSearch,
  searchData,
  setPlace,
  removeAllMarks,
  setDestinations,
  destinations,
  removeAllLine,
  changeBestPoint,
  setBestPoints,
  distance,
  setDistance,
  result,
  setResultNumber,
  onChange,
  setFilters,
  resultNumber,
  resultAccept,
  setResultAccept,
  removePopup,
  width,
  px,
  way,
  setWay,
  map,
  idPlace,
  onClick,
  setLastWayResult,
  setPointAgain,
  filters,
  setTripPlace,
  setLoading,
  setIranMarker,
  ref1,
  ref2,
  ref3,
  ref4,
  ref5,
  ref6,
  ref7,
  ref8,
  ref9,
  ref10,
  ref11,
  ref12,
  closing,
  setClosing,
  removePopups,
  place,
}) {
  const [showAcc, setShowAcc] = useState(false);
  const [category, setCategory] = useState(false);
  const [filtersData, setFiltersData] = useState();
  const [thefilters, setTheFilters] = useState();
  const [filterCategory, setFilterCategory] = useState();
  const [selectFilter, setSelectFilter] = useState();
  const fi = true;

  useEffect(() => {
    if (way) return;
    setResultNumber(null);
    removePopup();
    removePopups();
  }, [result]);

  useEffect(() => {
    getFilters()
      .then((res) => {
        let data = [];
        res.result.filters.map((param) =>
          param.body.map((x) => data.push({ ...x }))
        );
        setFilterCategory(res.result.filters);
        setFiltersData(data);
        setTheFilters(
          data.map(() => {
            return [];
          })
        );
      })
      .catch((error) => console.error(error));
  }, [fi]);

  let showButton = Boolean(
    destinations && destinations[0]?.lat && destinations[1]?.lat
  );

  const removePointMarker = () => {
    pointMarkers.forEach((marker) => marker.remove());
    pointMarkers = [];
  };

  useEffect(() => {
    removePointMarker();
  }, [destinations]);

  const changeTab = () => {
    setResultAccept(false);
    removePointMarker();
    removeAllMarks();
    removePopup();
    removeAllLine();
    setDestinations([{ key: 0 }, { key: 1, color: "#009BB9" }]);
  };

  useEffect(() => {
    if (category) {
      setCategory(false);
    } else {
      changeTab();
    }
    setPlace();
    changeBestPoint([]);
    setHidden(false);
  }, [way]);

  useEffect(() => {
    changeTab();
  }, [category]);

  const [hidden, setHidden] = useState(false);

  const phone = useMediaQuery("(max-width:520px)");

  return (
    <>
      <Box
        position="fixed"
        left="0"
        zIndex={2}
        width={phone ? "100%" : width}
        height={"fit-content"}
        px={px}
        top="50px"
        bottom="0"
        bgcolor=""
        pb="20px"
        maxHeight={"calc(100vh - 70px)"}
        sx={{
          transition: "0.5s all",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            transition: "0.5s all",
          }}
          // display={hidden ? "none" : ""}
        >
          <FirstFilter way={way} setWay={setWay} />
          {way ? (
            <FieldArrayFilter
              ref1={ref1}
              ref2={ref2}
              ref3={ref3}
              ref4={ref4}
              ref5={ref5}
              ref6={ref6}
              ref7={ref7}
              ref8={ref8}
              ref9={ref9}
              ref10={ref10}
              ref11={ref11}
              ref12={ref12}
              display={showAcc ? "none" : ""}
              setDestinations={setDestinations}
              destinations={destinations}
              hidden={hidden}
            />
          ) : (
            <SearchFilter
              hidden={hidden}
              setCategory={setCategory}
              category={category}
              setSearch={setSearch}
              searchData={searchData}
              setPlace={setPlace}
            />
          )}
          {/* )} */}
          {/* {
            <SearchNearPoints
              display={mode ? (showAcc ? "" : "none") : ""}
              setBestPoints={setBestPoints}
              onChange={onChange}
            />
          } */}
          {category ? (
            <CategoryFilter
              closing={closing}
              setClosing={setClosing}
              display=""
              hidden={hidden}
              filtersData={filtersData}
              selectFilter={selectFilter}
              setSelectFilter={setSelectFilter}
              setIranMarker={setIranMarker}
              setResult={setResult}
              setLoading={setLoading}
              filterCategory={filterCategory}
            ></CategoryFilter>
          ) : (
            (place || (destinations[0].lat && destinations[1].lat)) && (
              <AccordionFilter
                distance={distance}
                setDistance={setDistance}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                filters={thefilters}
                setFilters={setTheFilters}
                filterCategory={filterCategory}
                setFilterCategory={setFilterCategory}
                display=""
                hidden={hidden}
                setBestPoints={setBestPoints}
                handleChange={(f) => setFilters(f)}
                resultNumber={result.length}
                setResultAccept={setResultAccept}
                setResultNumber={setResultNumber}
                result={result}
              />
            )
          )}
          {resultAccept ? (
            <ResultBox
              setLoading={setLoading}
              setDestinations={setDestinations}
              setTripPlace={setTripPlace}
              setPointAgain={setPointAgain}
              removePointMarks={removePointMarks}
              removePointMarker={removePointMarker}
              pointMarkers={pointMarkers}
              setResult={setResult}
              setLastWayResult={setLastWayResult}
              lastWayResult={lastWayResult}
              map={map}
              display={way ? "" : "none"}
              onChange={onChange}
              setBestPoints={setBestPoints}
              setResultAccept={setResultAccept}
              setResultNumber={setResultNumber}
              resultNumber={resultNumber}
              idPlace={idPlace}
              removeAllMarks={removeAllMarks}
              onClick={onClick}
              filters={filters}
              result={
                resultNumber !== (undefined || null)
                  ? result[resultNumber]
                  : undefined
              }
            />
          ) : (
            <ShowResult
              setClosing={setClosing}
              hidden={hidden}
              setHidden={() => {
                phone ? setHidden(true) : setHidden(hidden);
              }}
              map={map}
              setLoading={setLoading}
              setTripPlace={setTripPlace}
              onClickHidden={() => setHidden(!hidden)}
              data={result}
              resultNumber={resultNumber}
              setResultNumber={setResultNumber}
              setResultAccept={setResultAccept}
            />
          )}
        </Box>
        {phone && (
          <Box sx={{ zIndex: "10" }} mt={"5px"} ml={"5px"}>
            <ShowNab
              onClick={() => setHidden(!hidden)}
              text={" پنهان کردن پنل جستجو "}
              open={hidden}
            />
          </Box>
        )}
        {/* {phone && (
          <Box
            display={showButton ? "" : "none"}
            position={"fixed"}
            left={"10px"}
            bottom={"70px"}
            zindex="10"
            onClick={() => setShowAcc(!showAcc)}
            sx={
              result.length > 0
                ? { bottom: "150px", right: "10px", left: "" }
                : {}
            }
          >
            <Typography
              p={"5px 10px"}
              borderRadius={"10px"}
              bgcolor={"white"}
              color={"#C59358"}
              fontSize={"12px"}
            >
              {!showAcc ? " جستجو اطراف مسیر " : "تغییر مسیر"}
            </Typography>
          </Box>
        )} */}
      </Box>
    </>
  );
}
export default memo(MapBoxFilter);
