export default function Delete({ color, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "10.64"}
      height={width ? width : "10.64"}
      viewBox={width ? `0 0 ${width} ${width}` : "0 0 10.64 10.64"}
    >
      <path
        transform={`scale(${
          width ? Number(width) / 10.64 : 1
        }) translate(-11.285 -11.289)`}
        id="Icon_ionic-ios-close"
        data-name="Icon ionic-ios-close"
        d="M17.868,16.609l3.8-3.8a.891.891,0,0,0-1.26-1.26l-3.8,3.8-3.8-3.8a.891.891,0,1,0-1.26,1.26l3.8,3.8-3.8,3.8a.891.891,0,0,0,1.26,1.26l3.8-3.8,3.8,3.8a.891.891,0,0,0,1.26-1.26Z"
        fill={color ? color : "#e33156"}
      />
    </svg>
  );
}
