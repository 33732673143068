export default function Baggage({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.875"
      height="18.5"
      viewBox="0 0 13.875 18.5"
      className="svg_transition"
    >
      <path
        id="Icon_awesome-suitcase-rolling"
        data-name="Icon awesome-suitcase-rolling"
        d="M12.141,5.781H1.734A1.734,1.734,0,0,0,0,7.516v8.094a1.734,1.734,0,0,0,1.734,1.734h.578v.578a.578.578,0,0,0,.578.578H4.047a.578.578,0,0,0,.578-.578v-.578H9.25v.578a.578.578,0,0,0,.578.578h1.156a.578.578,0,0,0,.578-.578v-.578h.578a1.734,1.734,0,0,0,1.734-1.734V7.516A1.734,1.734,0,0,0,12.141,5.781Zm-.578,7.8a.289.289,0,0,1-.289.289H2.6a.289.289,0,0,1-.289-.289v-.578a.289.289,0,0,1,.289-.289h8.672a.289.289,0,0,1,.289.289Zm0-3.469a.289.289,0,0,1-.289.289H2.6a.289.289,0,0,1-.289-.289V9.539A.289.289,0,0,1,2.6,9.25h8.672a.289.289,0,0,1,.289.289ZM5.2,1.734H8.672V4.625h1.734V1.734A1.734,1.734,0,0,0,8.672,0H5.2A1.734,1.734,0,0,0,3.469,1.734V4.625H5.2Z"
        fill={color ? color : "#fff"}
      />
    </svg>
  );
}
