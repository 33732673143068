import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import Baggage from "../assets/baggage";
import Search from "../assets/search";

let skeleton;

export default function Popup({ result, resultNumber, setResultNumber }) {
  useEffect(() => {
    if ((skeleton = true && resultNumber)) {
      skeleton = false;
      setResultNumber(resultNumber + Math.random());
    }
  }, [resultNumber]);

  const ImageORSkeleton = () => {
    return (
      <>
        {skeleton ? (
          <Skeleton
            width="60px"
            height="100px"
            sx={{ transform: "scale(1 , 1)" }}
          ></Skeleton>
        ) : (
          <img
            src={result[resultNumber].pic}
            width="60px"
            height="60px"
            alt=""
            onError={() => {
              setResultNumber(resultNumber + Math.random());
              skeleton = true;
            }}
          />
        )}
      </>
    );
  };

  return (
    <Box sx={{ display: "none" }}>
      <Box id="popup">
        {result[resultNumber] && (
          <Box display="flex" justifyContent="center">
            <Box
              width="60px"
              height="60px"
              borderRadius="5px"
              overflow="hidden"
            >
              <ImageORSkeleton />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
              pr="10px"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  color="#8E8E8E"
                  fontSize="14px"
                  sx={{
                    whiteSpace: "nowrap",
                    maxWidth: "80px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {result[resultNumber]?.name}
                </Typography>
                <Box height="fit-content" display="flex">
                  <Box
                    my="2px"
                    height="12px"
                    bgcolor="#B4B4B4"
                    width="0.5px"
                    mx="4px"
                  />
                  <Typography
                    color="#B4B4B4"
                    fontSize="10px"
                    sx={{
                      whiteSpace: "nowrap",
                      width: "50px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {result[resultNumber]?.type === "attraction"
                      ? "جاذبه"
                      : result[resultNumber].type === "facility"
                      ? result[resultNumber].slogan || "تاسیسات"
                      : "مکان"}
                  </Typography>
                </Box>
              </Box>
              <Box alignSelf="flex-end">
                <IconButton id="ResultAccept">
                  <Search width="18.5" />
                </IconButton>
                <IconButton id="addToTrip">
                  <Baggage color="#b4b4b4" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
