import { useState } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";

import Arrow from "../assets/arrow";
import Route from "../assets/route";
import SearchIcon from "../assets/search";

export default function FirstFilter({ way, setWay }) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <>
      <Box
        width={phone ? "100%" : "325px"}
        height="50px"
        position="relative"
        mt="20px"
        borderRadius="10px"
        overflow="hidden"
        zIndex={1}
        display={phone ? "none" : "flex"}
        boxShadow="0 3px 6px #00000025"
      >
        <Box
          width={way ? "calc(73% - 8px)" : "calc(27% - 8px)"}
          sx={{
            transition: "all 0.4s",
            backgroundImage: way
              ? "linear-gradient(to right, #C99962 , #ffffff)"
              : "linear-gradient(to right, #ffffff , #ffffff)",
            cursor: way ? "auto" : "pointer",
          }}
          onClick={() => setWay(true)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={way ? "row" : "column"}
          p="4px"
        >
          <Route
            width={way ? "30" : "18.5"}
            color={way ? "#C59358" : "#B4B4B4"}
          />
          <Box
            display="flex"
            flexDirection="column"
            mt="-4px"
            pl={way ? "13px" : ""}
            alignItems={way ? "flex-end" : "center"}
          >
            <Typography
              width="fit-content"
              fontSize={!way ? "10px" : "15px"}
              fontWeight={!way ? "Regular" : "Bold"}
              color={!way ? "#C5C5C5" : "#555A5D"}
            >
              کاوش در مسیر
            </Typography>
            {way && (
              <Typography fontSize="10px" color="#555A5D">
                در مسیر گردشگری جستجو کنید
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          width={way ? "calc(27% - 8px)" : "calc(73% - 8px)"}
          sx={{
            transition: "all 0.4s",
            backgroundImage: way
              ? "linear-gradient(to right, #ffffff , #ffffff)"
              : "linear-gradient(to right, #ffffff , #C99962)",
            cursor: way ? "pointer" : "auto",
          }}
          onClick={() => setWay(false)}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={way ? "column" : "row-reverse"}
          p="4px"
        >
          <SearchIcon
            width={way ? "18.5" : "30"}
            color={way ? "#B4B4B4" : "#C59358"}
          />
          <Box
            display="flex"
            flexDirection="column"
            mt="-4px"
            pr={way ? "" : "10px"}
          >
            <Typography
              fontSize={way ? "10px" : "15px"}
              fontWeight={way ? "Regular" : "Bold"}
              color={way ? "#C5C5C5" : "#555A5D"}
            >
              کاوش در نقاط
            </Typography>
            {!way && (
              <Typography fontSize="10px" color="#555A5D">
                در نقاط گردشگری جستجو کنید
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          position="absolute"
          height="100%"
          display="flex"
          alignItems="center"
          left={way ? "25%" : "70%"}
          sx={{ transition: "all 0.4s", rotate: way ? "180deg" : "0deg" }}
        >
          <Box
            width="16.6px"
            height="16.6px"
            borderRadius="50%"
            bgcolor="white"
          >
            <Arrow setWay={setWay} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
