import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

import AccIcon from "../assets/accIcon";
import Search from "../assets/search";

export default function SearchNearPoints({
  setBestPoints,
  display,
  setDistance,
  distance,
  inPost,
  setResultNumber
}) {
  const phone = useMediaQuery("(max-width:520px)");

  const handleChange = (event) => {
    setDistance(event.target.value);
  };

  return (
    <Box
      // width={phone ? "400px" : "100%"}
      width={phone ? "100%" : "calc(325px - 26px)"}
      position="relative"
      display={display}
      // mt={phone ? "3px" : "5px"}
      // borderRadius={phone ? "0px" : "10px"}
      overflow="hidden"
      zIndex={1}
      // boxShadow="0 3px 6px #00000025"
      // p="6px 13px"
      bgcolor="white"
    >
      {/* {!phone && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="#8E8E8E">اطراف را ببینید</Typography>
        </Box>
      )} */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <label htmlFor="searchNear" style={{ cursor: "pointer" }}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Typography
              // onClick={() => setBestPoints(distance)}
              color={inPost ? "#0066B1" : "#8E8E8E"}
              fontSize="12px"
            >
              {inPost ? "مشاهده نزدیک ترین ها" : "شعاع نمایش اطراف:"}
            </Typography>
            {inPost && (
              <IconButton
                id="searchNear"
                onClick={() => {
                  if (inPost) setResultNumber(0);
                  setBestPoints(distance);
                }}
              >
                <Search width="18.5" color="#0066B1" />
              </IconButton>
            )}
          </Box>
        </label>
        <FormControl variant="standard" sx={{ minWidth: 80 }}>
          <Select
            IconComponent={(props) => {
              return <AccIcon {...props} />;
            }}
            value={distance || 10}
            onChange={handleChange}
            sx={{
              fontSize: "12px",
              color: "#707070",
              "::before": {
                border: "0 !important",
                ":hover": { border: "0 !important" },
                ":focus": { border: "0 !important" },
              },
            }}
          >
            <MenuItem sx={{ fontSize: "12px", color: "#707070" }} value={10}>
              10 کیلومتر
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px", color: "#707070" }} value={20}>
              20 کیلومتر
            </MenuItem>
            <MenuItem sx={{ fontSize: "12px", color: "#707070" }} value={30}>
              30 کیلومتر
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
