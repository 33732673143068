import { Checkbox } from "@mui/material";

export default function CheckboxButton({
  children,
  value,
  setValue,
  selectedValues,
  readOnly,
}) {
  const handleChange = (theValue) => {
    if (selectedValues.find((x) => x === value) === value) {
      setValue(selectedValues.filter((x) => x !== theValue));
    } else {
      setValue((x) => [...x, theValue]);
    }
  };

  return (
    <>
      <label htmlFor={String(value)} style={{ cursor: "pointer" }}>
        {children}
      </label>
      <Checkbox
        sx={{ display: "none", color: "#009BB9" }}
        id={String(value)}
        checked={selectedValues.find((x) => x === value) ? true : false}
        onChange={() => !readOnly && handleChange(value)}
        value={value}
      />
    </>
  );
}
