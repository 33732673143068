export default function GuideIcon({ color, width }) {
  let height = width ? width * (17.703 / 20.232) : 17.703;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "20.232"}
      height={height ? `${height}` : "17.703"}
      viewBox={width ? `0 0 ${width} ${height}` : "20.232 17.703"}
      className="svg_transition"
    >
      <path
        id="Icon_metro-table"
        data-name="Icon metro-table"
        d="M2.571,3.856v17.7H22.8V3.856H2.571Zm7.587,11.38V11.443h5.058v3.793ZM15.216,16.5v3.793H10.158V16.5Zm0-10.116v3.793H10.158V6.385Zm-6.322,0v3.793H3.835V6.385ZM3.835,11.443H8.893v3.793H3.835V11.443Zm12.645,0h5.058v3.793H16.48V11.443Zm0-1.264V6.385h5.058v3.793ZM3.835,16.5H8.893v3.793H3.835V16.5ZM16.48,20.294V16.5h5.058v3.793Z"
        transform={`scale(${width ? Number(width) / 20.232 : 1},${
          height ? Number(height) / 17.703 : 1
        }) translate(-2.571 -3.856)`}
        fill={color ? color : "#e33156"}
      />
    </svg>
    // <svg  width="20.232" height="17.703" viewBox="0 0 20.232 17.703">
    //   <path    transform="translate(-2.571 -3.856)" fill="#555a5d"/>
    // </svg>
  );
}
