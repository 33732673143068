export default function Save({ color, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "16.99"}
      height={width ? `${width}` : "16.99"}
      viewBox={width ? `0 0 ${width} ${width}` : `0 0 16.99 16.99`}
    >
      <path
        id="Icon_awesome-save"
        data-name="Icon awesome-save"
        transform={`scale(${width ? Number(width) / 16.99 : 1} ,${
          width ? Number(width) / 16.99 : 1
        }) translate(0 -2.25)`}
        fill={color ? color : "#e33156"}
        d="M16.457,5.964,13.276,2.783a1.82,1.82,0,0,0-1.287-.533H1.82A1.82,1.82,0,0,0,0,4.07V17.42a1.82,1.82,0,0,0,1.82,1.82H15.17a1.82,1.82,0,0,0,1.82-1.82V7.252a1.82,1.82,0,0,0-.533-1.287ZM8.5,16.813a2.427,2.427,0,1,1,2.427-2.427A2.427,2.427,0,0,1,8.5,16.813ZM12.136,5.264V9.077a.455.455,0,0,1-.455.455h-8.8a.455.455,0,0,1-.455-.455V5.132a.455.455,0,0,1,.455-.455h8.667a.455.455,0,0,1,.322.133L12,4.942a.455.455,0,0,1,.133.322Z"
      />
    </svg>
  );
}
