import pic1 from "./1/1.png";
import pic2 from "./2/1.png";
import pic3 from "./3/1.png";
import pic32 from "./3/2.png";
import pic4 from "./4/1.png";
import pic42 from "./4/2.png";
import pic43 from "./4/3.png";
import pic5 from "./5/1.png";
import pic52 from "./5/2.png";
import pic6 from "./6/1.png";
import pic7 from "./7/1.png";
import pic72 from "./7/2.png";
import pic73 from "./7/3.png";
import pic74 from "./7/4.png";
import pic8 from "./8/1.png";
import pic82 from "./8/2.png";
import pic83 from "./8/3.png";
import pic84 from "./8/4.png";
import pic85 from "./8/5.png";
import pic9 from "./9/1.png";
import pic92 from "./9/2.png";
import pic93 from "./9/3.png";
import pic10 from "./10/1.png";
import pic102 from "./10/2.png";
import pic103 from "./10/3.png";
import pic11 from "./11/1.png";
import pic12 from "./12/1.png";
import pic122 from "./12/2.png";
import pic123 from "./12/3.png";
import pic124 from "./12/4.png";
import pic13 from "./13/1.png";
import pic14 from "./14/1.png";
import pic15 from "./15/1.png";
import pic152 from "./15/2.png";
import pic153 from "./15/3.png";
import pic16 from "./16/1.png";
import pic162 from "./16/2.png";
import pic163 from "./16/3.png";
import pic164 from "./16/4.png";
import pic17 from "./17/1.png";
import TextWithTitel from "./textWithTitle";

export default function slides(phone) {
  return [
    {
      pic: pic1,
      description: (
        <>
          <TextWithTitel phone={phone} title="کاوش در نقاط">
            با استفاده از این گزینه شما می توانید نقطه و یا نقاط گردشگری را
            جستجو کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone} title="کاوش در مسیر">
            با استفاده از این گزینه شما می توانید پس از تعیین مبدا و مقصد، علاوه
            بر مسیر یابی، نقاط گردشگری اطراف مسیر را مشاهده کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone} title="جستجو بر روی نقشه">
            با زوم کردن بر روی نقشه، اگر محدوده نمایش داده شده به اندازه مناسب
            کوچک باشد می توانید با دکمه (جستجو در این مکان) آن محدوده را جستجو
            کنید.
          </TextWithTitel>
        </>
      ),
      label: "انواع جست و جو",
    },
    {
      pic: pic2,
      description: (
        <>
          <TextWithTitel phone={phone} title="کاوش در مکان">
            با انتخاب این گزینه می توانید مکان گردشگری خود وارد کرده و از
            پیشنهادات ارایه شده آن را انتخاب کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone} title="کاوش در دسته">
            با انتخاب این گزینه شما می توانید تمام مکان های گردشگری را که دارای
            خصوصیات یکسانی هستند مشاهده کنید. مانند تمامی آبشارها و یا جنگل ها
          </TextWithTitel>
        </>
      ),
      label: "کاوش در نقاط",
    },
    {
      pic: pic3,
      description: (
        <TextWithTitel phone={phone}>
          برای جستجو در دسته ها ابتدا باید از بین لیست دسته های موجود یعنی جاذبه
          های گردشگری (مانند ابشار و جنگل) و تاسیسات (مانند اقامتگاه، هتل و
          رستوران) یک گزینه را انتخاب نموده و دکمه{" "}
          <img
            height="30"
            className="verticalAlign"
            src={pic32}
            alt=""
          />
          را انتخاب کنید.
        </TextWithTitel>
      ),
      label: "جستجو در دسته",
    },
    {
      pic: pic4,
      description: (
        <TextWithTitel phone={phone}>
          برای کاوش در مسیر گردشگری، ابتدا باید مبدا و مقصد خود را انتخاب
          نمایید. شما می توانید با{" "}
          <img
            height="30"
            className="verticalAlign"
            src={pic43}
            alt=""
          />{" "}
          انتخاب گزینه به انتهای مسیر مقصد جدیدی اضافه کنید و با{" "}
          <img
            height="30"
            className="verticalAlign"
            src={pic42}
            alt=""
          />{" "}
          آن را پاک کنید.
        </TextWithTitel>
      ),
      label: "کاوش در مسیر",
    },
    {
      pic: pic5,
      description: (
        <TextWithTitel phone={phone}>
          اگر به اندازه کافی بر روی نقشه زوم کرده باشید، دکمه جستجو در این مکان
          بر روی نقشه ظاهر می شود، این دکمه به شما اجازه جستجو در آن محدوده
          جغرافیایی را خواهد داد. تنظیمات جستجو از قسمت دقیقتر شوید قابل شخصی
          سازی است.
        </TextWithTitel>
      ),
      label: "جستجو بر روی نقشه",
    },
    {
      pic: pic6,
      description: (
        <>
          <TextWithTitel phone={phone}>
            این گزینه به شما امکان می دهد اطراف محل جستجو و یا مسیر خود را مطابق
            سلیقه خودتان و به صورت کاملا دقیق تر جستجو کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone} title="دسته ها">
            اگر دسته های کلی نیاز شما را بر ظطرف می کنند می توانید به سادگی آن
            ها را انتخاب کنید، اگر نیاز به دسته های جزئی تری دارید با دکمه{" "}
            <img
              height="10"
              className="verticalAlign"
              src={pic52}
              alt=""
            />{" "}
            (بالا سمت چپ) به منوی تخصصی ما وارد شوید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            پس از انتخاب شعاع جستجو حتما از گزینه اعمال فیلتر برای نمایش نتایج
            استفاده کنید. دکمه حذف فیلتر همواره شما را به حالت اول باز می
            گرداند.
          </TextWithTitel>
        </>
      ),
      label: "دقیقتر شوید",
    },
    {
      pic: pic7,
      description: (
        <>
          <TextWithTitel phone={phone}>
            نتایج جستجوی خود را همواره می توانید از طریق پنل نتایج مشاهده کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic72}
              alt=""
            />{" "}
            این دکمه به شما کمک می کند تا مکان هایی که مشاهده می کنید را به
            برنامه سفر خود اضافه کرده و سفر خود را به طور کامل برنامه ریزی کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic73}
              alt=""
            />{" "}
            این دکمه مکان مورد نظر را بر روی نقشه مشخص می کند.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic74}
              alt=""
            />{" "}
            اگر به دنبال جزئیات یک مکان هستید این دکمه را انتخاب کنید.
          </TextWithTitel>
        </>
      ),
      label: "نمایش نتایج",
    },
    {
      pic: pic8,
      description: (
        <>
          <TextWithTitel phone={phone}>
            در این قسمت شما می توانید اطلاعات کامل مکان را مشاهده کنید. در صروتی
            که امکان رزرو وجود داشته باشد آن را رزرو کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            {" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic82}
              alt=""
            />{" "}
            توضیحات کامل آن را مشاهده کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            {" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic83}
              alt=""
            />{" "}
            از طریق نرم افزارهای مسیریابی به آنجا هدایت شوید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            {" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic84}
              alt=""
            />{" "}
            به عنوان مقصد برای کاوش در مسیر آن را انتخاب کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            {" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic85}
              alt=""
            />{" "}
            نزدیکترین مکان ها به آن را بیابید.
          </TextWithTitel>
        </>
      ),
      label: "مشاهده جزئیات مکان",
    },
    {
      pic: pic9,
      description: (
        <TextWithTitel phone={phone}>
          در این قسمت شما می توانید نام مکان را بر روی نقشه مشاهده کنید. همچنین
          به راحتی به اطلاعات تکمیلی (
          <img
            height="30"
            className="verticalAlign"
            src={pic92}
            alt=""
          />
          ) آن دسترسی پیدا کرده و یا ان را به سفر خود (
          <img
            height="30"
            className="verticalAlign"
            src={pic93}
            alt=""
          />
          ) اضافه کنید.
        </TextWithTitel>
      ),
      label: "نمایشگر اطلاعات مکان بر روی نقشه",
    },
    {
      pic: pic10,
      description: (
        <>
          <TextWithTitel phone={phone}>
            با اضافه کردن هر مکان به سفرهای خود، باکس سفر من باز می شود. در این
            باکس شما می توانید مکان را به سفرهای پیشین خود و یا به سفر جدیدی که
            ایجاد می کنید اضافه کنید. برای انتخاب سفرهای پیشین از دکمه انتخاب در
            کنار نام آن و برای اضافه کردن سفر جدید از دکمه ایجاد سفر جدید در
            بالا سمت راست استفاده کنید.{" "}
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            همواره متوانید از طریق{" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic102}
              alt=""
            />{" "}
            به سفرهای خود دسترسی داشته باشید. این امکان فقط برای کسانی که عضو
            سایت هستند میسر است پس حتما از طریق دکمه{" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic103}
              alt=""
            />{" "}
            وارد سایت شوید.
          </TextWithTitel>
        </>
      ),
      label: "سفر های من",
    },
    {
      pic: pic11,
      description: (
        <>
          <TextWithTitel phone={phone}>
            در این قسمت شما می توانید مبدا سفر و تاریخ شروع و پایان آن را انتخاب
            کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            همچنین از بین گزینه های موجود اقدام به انتخاب نوع سفر خود نمایید.
          </TextWithTitel>
        </>
      ),
      label: "سفرهای من – اطلاعات  کلی سفر",
    },
    {
      pic: pic12,
      description: (
        <>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic122}
              alt=""
            />{" "}
            با این دکمه شما می توانید دوباره به نقشه بازگردید تا مکان های جدیدی
            را به سفر خود انتقال دهید
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic123}
              alt=""
            />{" "}
            همواره با دکمه ذخیره تغییرات خود را در سفر ذخیره کنید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic124}
              alt=""
            />{" "}
            با دکمه مسیریابی شما می توانید مسیر طراحی شده خود را به همراه زمان
            سفر و مسیر ناوبری مشاهده کنید. توجه کند این امکان تنها زمانی فعال
            (آبی رنگ) است که تمامی مکان های موجود در داخل سفر شما دارای تاریخ
            باشند.
          </TextWithTitel>
        </>
      ),
      label: "سفرهای من – دکمه های عملیاتی",
    },
    {
      pic: pic13,
      description: (
        <TextWithTitel phone={phone}>
          دراین قسمت شما می توانید نام سفر را تغییر دهید. لینک آن را برای اشتراک
          گذاری با دوستانتان کپی کنید. (برای این کار باید سفر خود را عمومی کرده
          و سپس لینک سفر را کپی کنید. ) و همینطور میتوانید سفر خود را حذف کنید.
        </TextWithTitel>
      ),
      label: "سفرهای من – تنظیمات",
    },
    {
      pic: pic14,
      description: (
        <TextWithTitel phone={phone}>
          برای استفاده از تمامی امکانات برنامه، تمامی مکان های باید تاریخ بازدید
          داشته باشند. این تاریخ، تاریخی مابین شروع و پایان سفر شماست، درنتیجه
          فقط همین تاریخ را می توانید انتخاب کنید. این تاریخ در بالای مکان های
          نمایش داده می شود. همچنین ترتیب قرار گرفتن مکان ها نشان دهنده اولویت
          بازدید آن هاست و در مسیر یابی لحاظ می گردد. این اولویت با عدد در کنار
          مکان ها نمایش داده شده است.
        </TextWithTitel>
      ),
      label: "سفرهای من – مدیریت مکان ها",
    },
    {
      pic: pic15,
      description: (
        <>
          <TextWithTitel phone={phone}>
            با استفاده از دکمه{" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic152}
              alt=""
            />
            می توانید تاریخ بازدید از مکان مورد نظر را انتخاب کنید. در صورت
            انتخاب تاریخ در کنار همین دکمه می توانید تاریخ انتخاب شده را مشاهده
            و یا ویرایش نمایید.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            مکان ها بر اساس تاریخ در دسته های یکسان قرار می گیرند. دسته نیازمند
            اختصاص تاریخ (اولین تصویر) شامل مکان های بدون تاریخ است. دومین تصویر
            مکان هایی است که تاریخ مشخصی دارند. شما همواره می توانید با دکمه{" "}
            <img
              height="10"
              className="verticalAlign"
              src={pic153}
              alt=""
            />{" "}
            اقدام به بستن دسته تاریخ ها نمایید تا تمامی آن را در یک نگاه مشاهده
            کنید. (سومین تصویر). با انتخاب هر دسته تاریخ، مکان های آن تاریخ را
            مشاهده کنید.
          </TextWithTitel>
        </>
      ),
      label: "سفرهای من – اختصاص تاریخ",
    },
    {
      pic: pic16,
      description: (
        <>
          <TextWithTitel phone={phone}>
            با استفاده از دکمه{" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic162}
              alt=""
            />{" "}
            می توانید مدت حضور خود در یک مکان را مشخص کنید. همچنین با کلیک بر
            روی گرفتن هر مکان و کشیدن آن به بالا و یا پایین اولویت بازدید آن را
            در هر روز تغییر دهید. این اولویت به صورت عدد در داخل دایره خاکستری
            نمایش داهد شده است.{" "}
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            <img
              height="30"
              className="verticalAlign"
              src={pic163}
              alt=""
            />
            با این دکمه می توانید اطلاعات مکان را در وب سایت ببینید و همجنین
            برای حذف مکان هم از دکمه{" "}
            <img
              height="30"
              className="verticalAlign"
              src={pic164}
              alt=""
            />{" "}
            منو استفاده کنید.
          </TextWithTitel>
        </>
      ),
      label: "سفرهای من – دکمه های عملیاتی مکان ها",
    },
    {
      pic: pic17,
      description: (
        <>
          <TextWithTitel phone={phone}>
            با زدن دکمه مسیر یابی، برنامه طبق تاریخ هر مکان و اولویت های مکان ها
            اقدام به نمایش مسیر تردد بین آن ها برای روزهای مختلف می کند.
          </TextWithTitel>
          <TextWithTitel phone={phone}>
            اگر مکان ها دارای تاریخ نباشند این دکمه در دسترس نیست.
          </TextWithTitel>
        </>
      ),
      label: "سفرهای من – مسیر یابی",
    },
  ];
}
