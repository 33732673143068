import {
  Box,
  Collapse,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import AccIcon from "../../assets/accIcon";
import OptionResult2 from "./optionResult2";
import { toast } from "react-toastify";
import { updateBatch } from "../createTripDialog/api/trip";

export function Title({ title, onClick, showAcc, layer }) {
  return (
    <Box
      onClick={onClick}
      display={"flex"}
      justifyContent="space-between"
      m={"5px"}
      sx={{ cursor: "pointer" }}
      alignItems="center"
    >
      <Typography fontSize={"14px"}>{title}</Typography>
      <Box
        sx={{
          transform: showAcc ? "rotate(0deg)" : "rotate(90deg)",
          transition: "0.5s all",
        }}
      >
        <AccIcon />
      </Box>
    </Box>
  );
}

export function ImageBox({ param }) {
  const [shimmer, setShimmer] = useState(false);
  return (
    <Box alignItems={"center"} display={"flex"}>
      {shimmer ? (
        <Skeleton width="30px" height="50px"></Skeleton>
      ) : (
        <img
          style={{ borderRadius: "5px", width: "31px", height: "31px" }}
          src={param?.pics?.T || param?.thumbnail || ""}
          alt={param?.name || ""}
          onError={() => {
            setShimmer(true);
          }}
        />
      )}
      <Typography color={"#8E8E8E"} fontSize={"14px"} mr={"5px"}>
        {param?.name || ""}
      </Typography>
      {/* <Typography mr={"5px"} mt={"5px"} color={"#B4B4B4"} fontSize={"8px"}>
        {"استان"}
      </Typography> */}
    </Box>
  );
}

export function Line({ bgcolor, width, margin }) {
  return (
    <Box
      margin={margin}
      bgcolor={bgcolor ? bgcolor : "black"}
      width={width}
      height={"1px"}
    ></Box>
  );
}

export default function Result({
  tripPlace,
  tripId,
  data,
  setGetAgain,
  setData,
  minDate,
  maxDate,
  update,
  setUpdate,
  layer,
  removeLayer,
}) {
  const [showAcc1, setShowAcc1] = useState(true);
  const [showAcc3, setShowAcc3] = useState(null);
  const phone = useMediaQuery("(max-width:520px)");
  const [unicDate, setUnicDate] = useState([]);
  const [NewData, setNewData] = useState([
    ...data.data.places
      .filter((x) => x.at !== null)
      .sort((a, b) => {
        let x = a.at.split("/"),
          y = b.at.split("/");
        if (Number(x[0]) > Number(y[0])) {
          return -1;
        } else if (Number(x[0]) === Number(y[0])) {
          if (Number(x[1]) > Number(y[1])) {
            return -1;
          } else if (Number(x[1]) === Number(y[1])) {
            if (Number(x[2] >= Number(y[2]))) {
              return -1;
            } else {
              return 1;
            }
          } else {
            return 1;
          }
        } else {
          return 1;
        }
      }),
    ...data.data.places.filter((x) => x.at === null),
  ]);

  useEffect(() => {
    if (!update) return;
    if (NewData[0])
      updateBatch(tripId, {
        places: NewData.map((x) => {
          if (x.duration !== 0) {
            if (x.at) {
              return { place_id: x.place.code, at: x.at, duration: x.duration };
            } else {
              return { place_id: x.place.code, duration: x.duration };
            }
          } else {
            if (x.at) {
              return { place_id: x.place.code, at: x.at };
            } else {
              return { place_id: x.place.code };
            }
          }
        }),
      })
        .then(() => toast.success("ترتیب و تاریخ مکان ها با موفقیت ذخیره شد"), {
          autoClose: 6000,
        })
        .catch(
          () => toast.error("ثبت ترتیب و تاریخ مکان ها با مشکل مواجه شد"),
          {
            autoClose: 6000,
          }
        );
    setUpdate((x) => !x);
  }, [update]);

  useEffect(() => {
    let d = [
      ...data.data.places
        .filter((x) => x.at !== null)
        .sort((a, b) => {
          let x = a.at.split("/"),
            y = b.at.split("/");
          if (Number(x[0]) > Number(y[0])) {
            return 1;
          } else if (Number(x[0]) === Number(y[0])) {
            if (Number(x[1]) > Number(y[1])) {
              return 1;
            } else if (Number(x[1]) === Number(y[1])) {
              if (Number(x[2] >= Number(y[2]))) {
                return 1;
              } else {
                return -1;
              }
            } else {
              return -1;
            }
          } else {
            return -1;
          }
        }),
      ...data.data.places.filter((x) => x.at === null),
    ];
    setNewData(d);
  }, [data.data.places]);

  useEffect(() => {
    let ud = [];
    NewData.filter((x) => x.at !== null).forEach((z) => {
      if (!ud.filter((i) => i === z.at)[0]) ud.push(z.at);
    });
    setUnicDate(ud);
    removeLayer();
  }, [NewData]);

  return NewData[0] ? (
    <Box
      width={phone ? "calc(99vw-10px)" : "335px"}
      height="fit-content"
      position="relative"
      mt={phone ? "3px" : "5px"}
      borderRadius={phone ? "0px" : "10px"}
      overflow="hidden"
      zIndex={1}
      bgcolor={"white"}
      boxShadow="0 3px 6px #00000025"
      alignItems={"center"}
      sx={{ transition: "1.5s all" }}
    >
      {/* <Box padding={"10px"} display={"flex"} justifyContent="space-between">
        <ImageBox city={"تهران"} />
        <Box>
          <Marker />
        </Box>
      </Box>
      <Line bgcolor={"#B4B4B4"} margin={"3px 0px"} /> */}

      {NewData.filter((x) => x.at === null)[0] && (
        <>
          <Title
            onClick={() => setShowAcc1(!showAcc1)}
            showAcc={showAcc1}
            title={"نیازمند اختصاص تاریخ"}
          />
          <Collapse in={showAcc1} timeout="auto">
            <OptionResult2
              minDate={minDate}
              maxDate={maxDate}
              onChange={(d) =>
                setData((x) => {
                  return {
                    ...x,
                    data: {
                      ...x.data,
                      places: [
                        ...x.data.places.filter((x) => x.at !== null),
                        ...d,
                      ],
                    },
                  };
                })
              }
              setData={setData}
              data={NewData.filter((x) => x.at === null)}
              tripId={tripId}
              tripPlace={tripPlace}
              setGetAgain={setGetAgain}
            />
          </Collapse>
        </>
      )}
      {NewData.filter((x) => x.at !== null)[0] &&
        NewData.filter((x) => x.at === null)[0] && (
          <Line bgcolor={"#B4B4B4"} margin={"3px 0px"} />
        )}
      {NewData.filter((x) => x.at !== null)[0] && (
        <>
          {unicDate.map((x, i) => (
            <React.Fragment key={i}>
              <Title
                onClick={() => setShowAcc3((z) => (z === i ? null : i))}
                showAcc={showAcc3 === i}
                title={x}
              />
              <Collapse in={showAcc3 === i} timeout="auto">
                <OptionResult2
                  layer={layer}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(d) => {
                    setData((item) => {
                      return {
                        ...item,
                        data: {
                          ...item.data,
                          places: [
                            ...d,
                            ...item.data.places.filter((item) => item.at !== x),
                          ],
                        },
                      };
                    });
                  }}
                  setData={setData}
                  data={NewData.filter((item) => item.at === x)}
                  tripId={tripId}
                  tripPlace={tripPlace}
                  setGetAgain={setGetAgain}
                />
              </Collapse>
              {i !== unicDate.length - 1 && (
                <Line bgcolor={"#B4B4B4"} margin={"3px 0px"} />
              )}
            </React.Fragment>
          ))}

          {/* <OptionResult2
            minDate={minDate}
            maxDate={maxDate}
            onChange={(d) => {
              setData((x) => {
                console.log({
                  ...x,
                  data: {
                    ...x.data,
                    places: [
                      ...d,
                      ...x.data.places.filter((x) => x.at === null),
                    ],
                  },
                });
                return x;
                // return {
                //   ...x,
                //   data: {
                //     ...x.data,
                //     places: [...d, ...x.data.places.filter((x) => x.at === null)],
                //   },
                // };
              });
            }}
            setData={setData}
            data={NewData.filter((x) => x.at !== null)}
            tripId={tripId}
            tripPlace={tripPlace}
            setGetAgain={setGetAgain}
          /> */}
        </>
      )}
    </Box>
  ) : (
    <Box
      width={phone ? "calc(99vw-30px)" : "315px"}
      height="fit-content"
      position="relative"
      mt={phone ? "3px" : "5px"}
      borderRadius={phone ? "0px" : "10px"}
      overflow="hidden"
      zIndex={1}
      bgcolor={"white"}
      boxShadow="0 3px 6px #00000025"
      alignItems={"center"}
      p="10px"
    >
      <Typography fontSize="14px" color="#000000">
        هیچ مقصدی انتخاب نشده است
      </Typography>
      <Typography fontSize="12px" color="#555a5d">
        برای اضافه کردن مقصد از قسمت جستجو مکانی اضافه کنید
      </Typography>
    </Box>
  );
}
