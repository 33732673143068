import React from "react";

export default function Settings({ transform, width, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "13.799"}
      height={width ? `${width}` : "13.801"}
      viewBox={width ? `0 0 ${width} ${width}` : `0 0 8.799 8.801`}
      style={{ transform: transform ? transform : "", transition: "0.5s all" }}
    >
      <path
        id="Icon_ionic-ios-settings"
        data-name="Icon ionic-ios-settings"
        d="M12.572,8.9A1.132,1.132,0,0,1,13.3,7.843a4.488,4.488,0,0,0-.543-1.308,1.147,1.147,0,0,1-.461.1,1.13,1.13,0,0,1-1.033-1.59A4.474,4.474,0,0,0,9.956,4.5a1.131,1.131,0,0,1-2.113,0,4.488,4.488,0,0,0-1.308.543A1.13,1.13,0,0,1,5.5,6.633a1.11,1.11,0,0,1-.461-.1A4.587,4.587,0,0,0,4.5,7.845a1.132,1.132,0,0,1,0,2.113,4.488,4.488,0,0,0,.543,1.308,1.13,1.13,0,0,1,1.492,1.492,4.514,4.514,0,0,0,1.308.543,1.129,1.129,0,0,1,2.108,0,4.488,4.488,0,0,0,1.308-.543,1.131,1.131,0,0,1,1.492-1.492A4.514,4.514,0,0,0,13.3,9.958,1.138,1.138,0,0,1,12.572,8.9ZM8.92,10.73A1.833,1.833,0,1,1,10.753,8.9,1.833,1.833,0,0,1,8.92,10.73Z"
        // transform={`translate(-4.5 -4.5)`}
        transform={`scale(${
          width ? Number(width) / 13.799 : 1
        }) translate(-4.5 -4.5)`}
        fill={color ? color : "#b4b4b4"}
      />
    </svg>
  );
}
