import { Radio } from "@mui/material";

export default function SelectBoxButton({
  children,
  value,
  setValue,
  selectedValue,
}) {
  const handleChange = (theValue) => {
    if (selectedValue === value) {
      setValue(null);
    } else {
      setValue(theValue);
    }
  };

  return (
    <>
      <label htmlFor={String(value)} style={{ cursor: "pointer" }}>
        {children}
      </label>
      <Radio
        sx={{ display: "none", color: "#009BB9" }}
        id={String(value)}
        checked={selectedValue === value}
        onClick={() => handleChange(value)}
        value={value}
      />
    </>
  );
}
