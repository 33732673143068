import { Box, Collapse, IconButton } from "@mui/material";
import AccIcon from "../assets/accIcon";

export default function AccordionBox({
  accordionData,
  number,
  setNumber,
  boxSx,
  onBoxClick,
  kepperSx,
  topBoxSx,
}) {
  return (
    <Box sx={kepperSx}>
      {accordionData.map((data, index) => {
        return (
          <Box
            sx={{
              ...boxSx,
              borderBottom:
                index === accordionData.length - 1 ? "0" : boxSx.borderBottom,
            }}
            key={index}
          >
            <Box
              sx={topBoxSx}
              style={{
                cursor: onBoxClick ? "pointer" : "auto",
                display: "flex",
              }}
              onClick={() => {
                if (number === index) setNumber(null);
                else setNumber(index);
              }}
            >
              {data.title}
              <IconButton
                sx={{
                  transform: !(number === index)
                    ? "rotate(0deg)"
                    : "rotate(-180deg)",
                  transition: "all 0.4s",
                  minWidth: "32px",
                  minHeight: "32px",
                }}
              >
                <AccIcon />
              </IconButton>
            </Box>
            <Collapse
              in={Boolean(index === number)}
              timeout="auto"
              unmountOnExit
            >
              {data.accordion}
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
}
