export default function Search({ color, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "18.5"}
      height={width ? `${width}` : "18.5"}
      viewBox={width ? `0 0 ${width} ${width}` : `0 0 23 23`}
      className="svg_transition"
    >
      <path
        id="Icon_ionic-md-search"
        data-name="Icon ionic-md-search"
        d="M17.321,15.814H16.5l-.308-.257a6.808,6.808,0,0,0,1.59-4.371,6.641,6.641,0,1,0-6.616,6.686,6.873,6.873,0,0,0,4.359-1.594l.308.257v.823L20.962,22.5,22.5,20.957Zm-6.154,0a4.629,4.629,0,1,1,4.615-4.629A4.6,4.6,0,0,1,11.167,15.814Z"
        transform={`scale(${
          width ? Number(width) / 18.5 : 1
        }) translate(-4.5 -4.5)`}
        fill={color ? color : "#b4b4b4"}
      />
    </svg>
  );
}
