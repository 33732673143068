export default function Route({ color, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "18.5"}
      height={width ? `${width}` : "18.5"}
      viewBox={width ? `0 0 ${width} ${width}` : `0 0 18.5 18.5`}
      className="svg_transition"
    >
      {
        (width !== "30" ? (
          <path
            strokeWidth={width ? `${width}` : "18.5"}
            id="Icon_awesome-route"
            data-name="Icon awesome-route"
            d="M15.031,11.563H11.563a1.156,1.156,0,0,1,0-2.312h3.469S18.5,5.384,18.5,3.469a3.469,3.469,0,0,0-6.937,0A7.622,7.622,0,0,0,13.2,6.938H11.563a3.469,3.469,0,0,0,0,6.938h3.469a1.156,1.156,0,0,1,0,2.313H6.7A24.946,24.946,0,0,1,4.994,18.5H15.031a3.469,3.469,0,0,0,0-6.937Zm0-9.25a1.156,1.156,0,1,1-1.156,1.156A1.155,1.155,0,0,1,15.031,2.313ZM3.469,9.25A3.47,3.47,0,0,0,0,12.719C0,14.634,3.469,18.5,3.469,18.5s3.469-3.866,3.469-5.781A3.47,3.47,0,0,0,3.469,9.25Zm0,4.625a1.156,1.156,0,1,1,1.156-1.156A1.155,1.155,0,0,1,3.469,13.875Z"
            fill={color ? color : "#b4b4b4"}
          />
        ) : (
          <path
            id="Icon_awesome-route"
            data-name="Icon awesome-route"
            d="M24.375,18.75H18.75a1.875,1.875,0,0,1,0-3.75h5.625S30,8.73,30,5.625a5.625,5.625,0,0,0-11.25,0c0,1.494,1.3,3.715,2.654,5.625H18.75a5.625,5.625,0,0,0,0,11.25h5.625a1.875,1.875,0,0,1,0,3.75H10.869A40.453,40.453,0,0,1,8.1,30H24.375a5.625,5.625,0,0,0,0-11.25Zm0-15A1.875,1.875,0,1,1,22.5,5.625,1.873,1.873,0,0,1,24.375,3.75ZM5.625,15A5.626,5.626,0,0,0,0,20.625C0,23.73,5.625,30,5.625,30s5.625-6.27,5.625-9.375A5.626,5.626,0,0,0,5.625,15Zm0,7.5A1.875,1.875,0,1,1,7.5,20.625,1.873,1.873,0,0,1,5.625,22.5Z"
            fill={color ? color : "#b4b4b4"}
          />
        ))
      }
    </svg>
  );
}
