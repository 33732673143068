import { useState } from "react";

import { Box, Button, Typography } from "@mui/material";
import PinInput from "react-pin-input";

import { useEffect } from "react";
import { Activate, signUp } from "./api/trip";
import { setTripToken } from "./api/token";
import { toast } from "react-toastify";

export default function Verification({
  setTrip,
  phone,
  reminder,
  setReminder,
}) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [minAndSec, setMinAndSec] = useState([2, 0]);

  useEffect(() => {
    let interval;
    let newTime = reminder;
    setMinAndSec([Math.floor(reminder / 60), reminder % 60]);
    interval = setInterval(() => {
      newTime = newTime - 1;
      setMinAndSec([Math.floor(newTime / 60), newTime % 60]);
      if (newTime === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }, [reminder]);

  useEffect(() => {
    if (code.length !== 5) return;
    if (isNaN(Number(code))) {
      setError(true);
    } else {
      setError(false);
    }
  }, [code]);

  return (
    <>
      <Typography color="#8E8E8E" fontSize="12" pr="8px">
        لطفا کد اعتبار سنجی ارسال شده به {phone} را وارد نمایید
      </Typography>
      <Box width="fit-content">
        <PinInput
          error={true}
          onComplete={(value) => {
            Activate({ phone: phone, code: value })
              .then((res) => {
                if (res.status === "ok") {
                  setTripToken(res.token);
                  setTrip(null);
                } else {
                  toast.error("کد صحیح را وارد کنید");
                }
              })
              .catch((error) => {
                console.log(error);
                setError(true);
              });
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{
            border: "0",
            borderBottom: " 1px #B4B4B4 solid",
            width: "26px",
            height: "26px",
          }}
          autoSelect
          style={{
            direction: "ltr",
            display: "flex",
            gap: "10px",
            margin: "10px",
          }}
          length={5}
          onChange={(value) => {
            setCode(value);
          }}
        />
      </Box>
      <Box display={phone ? "" : "flex"} alignItems="center">
        <Typography color="#8E8E8E" fontSize="12" pr="8px">
          هنوز کد را دریافت نکرده اید؟{" "}
          {(minAndSec[1] !== 0 || minAndSec[0] !== 0) &&
            `0${minAndSec[0]}:${
              minAndSec[1] >= 10 ? minAndSec[1] : "0" + minAndSec[1]
            }` + "دقیقه منتظر بمانید"}
        </Typography>
        <Button
          disabled={minAndSec[0] > 0 || minAndSec[1] > 0}
          sx={{ color: "#E33156" }}
          onClick={() => {
            signUp({ phone: phone })
              .then((res) => {
                setReminder(res.reminder);
                setMinAndSec([
                  Math.floor(res.reminder / 60),
                  res.reminder % 60,
                ]);
              })
              .catch((error) => console.log(error));
          }}
        >
          ارسال مجدد کد
        </Button>
      </Box>{" "}
      <Button
        onClick={() => {
          setTrip(1);
        }}
        sx={{
          color: "#00B2BC",
          fontSize: "12px",
          position: "absolute",
          bottom: phone ? "0px" : "20px",
          left: "20px",
        }}
      >
        تغییر شماره تلفن
      </Button>
    </>
  );
}
