import { Box, Typography, useMediaQuery } from "@mui/material";
import car from "../assets/car.svg";
import Num2persian from "num2persian";

export default function WayPopup({ x }) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <Box sx={{ display: phone ? "flex" : "none" }}>
      <Box
        sx={
          phone
            ? {
                bgcolor: "white",
                borderRadius: "10px",
                mr: "5px",
                p: "2px 5px",
                width: "180px",
              }
            : {}
        }
        id={`wayPopup${x.key}`}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <Typography fontSize="10px" color="#848484">
            مسیر {Num2persian(x.key + 1)}
          </Typography>
          {x.at && (
            <Typography fontSize="10px" color="#848484c0">
              {x.at}
            </Typography>
          )}
        </Box>
        {x.duration && (
          <Box display="flex" alignItems="center" gap="1px">
            <img src={car} alt="" />
            <Typography color="#555a5d" fontSize="14px">
              {x.duration.text.split(" ")[0]}
            </Typography>
            <Typography color="#848484" fontSize="10px">
              {x.duration.text.split(" ")[1]}
            </Typography>
            <Typography color="#555a5d" fontSize="14px">
              {x.duration.text.split(" ")[2]}
            </Typography>
            <Typography color="#848484" fontSize="10px">
              {x.duration.text.split(" ")[3]}
            </Typography>
          </Box>
        )}
        {x.distance && (
          <Box display="flex" alignItems="center" gap="1px">
            <Typography color="#555a5d" fontSize="14px">
              {x.distance.text.split(" ")[0]}
            </Typography>
            <Typography color="#848484" fontSize="10px">
              {x.distance.text.split(" ")[1]}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
