export const distancing = (first, second) => {
  var p = Math.PI / 180;
  var c = Math.cos;
  var a =
    0.5 -
    c((second[1] - first[1]) * p) / 2 +
    (c(first[1] * p) * c(second[1] * p) * (1 - c((second[0] - first[0]) * p))) /
      2;

  return 12742000 * Math.asin(Math.sqrt(a));
};

export const getbestPoints = (data, searchDistance) => {
  let points = [];
  let distance = 0;
  let mainDistance = searchDistance * 1000;
  for (let i = 0; i < data.length - 1; i++) {
    if (distance < mainDistance) {
      distance = distance + distancing(data[i], data[i + 1]);
    } else {
      points.push(data[i]);
      distance = 0;
    }
  }

  points.push(data[0]);
  points.push(data[data.length - 1]);

  return points;
};
