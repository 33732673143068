import "./App.css";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { polyline } from "./component/polyline";
import MapFilters from "./mapFilters";
import Header from "./component/header";
import {
  GetRoute,
  getRouteArea,
  getWeather,
  search,
} from "./component/mapApi/map";
import { distancing, getbestPoints } from "./map";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Popup from "./component/popup";
import TripDialog from "./component/createTripDialog";
import AccIcon from "./assets/accIcon";
import ShowNab from "./component/showNab";
import MenuPhone from "./component/menuPhone";
import CardLocation from "./component/cardLocation";
import MyTrip from "./MyTrip";
import { addTripPlaces, getTrips } from "./component/createTripDialog/api/trip";
import { getTripToken } from "./component/createTripDialog/api/token";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WayPopup from "./component/wayPopup";
import WeatherIcon from "./assets/weather";
import AddToHomeScreenButton from "./component/addToHomeScreen";
import InfoModal from "./component/InfoModal";
import GuideIcon from "./assets/guideIcon";

mapboxgl.setRTLTextPlugin(
  "https://cdn.parsimap.ir/third-party/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  null
);

let currentMarkers = [];
let pointMarkers = [];
let layerIndex = [];
let BestPointinlineData = [];
let popup;
let popups = [];

function App() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 49.8995;
  const lat = 33.3171;
  const zoom = 5.1;
  const [weather, setWeather] = useState(true);
  const [lastWayResult, setLastWayResult] = useState([]);
  const [searchValue, setSearch] = useState("");
  const [searchValueArray, setSearchArray] = useState();
  const [searchData, setSearchData] = useState();
  const [place, setPlace] = useState();
  const [lastPlace, setLastPlace] = useState();
  const [destinations, setDestinations] = useState([
    { key: 0 },
    { key: 1, color: "#009BB9" },
  ]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultNumber, setResultNumber] = useState(null);
  const [distance, setDistance] = useState(10);
  const [filters, setFilters] = useState();
  const [resultAccept, setResultAccept] = useState(false);
  const [trip, setTrip] = useState(null);
  const tablet = useMediaQuery("(max-width:800px)");
  const phone = useMediaQuery("(max-width:520px)");
  const [hidden, setHidden] = useState(true);
  const [way, setWay] = useState(false);
  const [showMyTrip, setShowMyTrip] = useState(false);
  const [tripId, setTripId] = useState();
  const [tripLength, setTripLength] = useState();
  const tripToken = getTripToken();
  const [tripPlace, setTripPlace] = useState([]);
  const [showResult, setShowResult] = useState(phone ? false : true);
  const [tripPlaceAdded, setTripPlaceAdded] = useState(true);
  const [clickLayer, setClickLayer] = useState(false);
  const [closing, setClosing] = useState([]);
  const [durationsAndDestance, setDurationsAndDestance] = useState([]);
  const [searchEreaButton, setSearchEreaButton] = useState(false);
  const [searchErea, setSearchErea] = useState();
  const [info, setInfo] = useState(!localStorage.getItem("infoSeen"));
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const ref11 = useRef();
  const ref12 = useRef();

  const removePopup = () => {
    if (popup) {
      popup.remove();
    }
  };

  useEffect(() => {});
  map.current?.on("zoom", () => {
    if (map.current.getZoom() > 12.5 && !way) {
      !searchEreaButton && setSearchEreaButton(true);
    } else {
      searchEreaButton && setSearchEreaButton(false);
    }
  });

  useEffect(() => {
    if (tripPlace[0]) return;
    if (!tripId) return;
    setShowMyTrip(true);
  }, [tripId]);

  useEffect(() => {
    setLoading(false);
    if (!tripPlace[0]) return;
    if (tripPlaceAdded || !tripId) {
      setTrip(getTripToken() ? 3 : 1);
      setTripPlaceAdded(false);
    } else if (tripPlace[0]) {
      addTripPlaces(tripId, {
        place_id: tripPlace[0].code,
        priority: 1000,
      }).then(() => {
        if (tripId) {
          toast.success("مکان شما به سفر انتخاب شده اضافه شد");
          setTripPlaceAdded(true);
          setTripPlace([]);
          setTripId(null);
        } else setTrip(1);
      });
    }
  }, [tripPlace, tripId]);

  useEffect(() => {
    setResultAccept(false);
    removeAllMarks();
    removePopup();
    removePopups();
    removeAllLine();
    BestPointinlineData = [];
    setDestinations([{ key: 0 }, { key: 1, color: "#009BB9" }]);
    setPlace();
  }, [showMyTrip]);

  useEffect(() => {
    if (!tripToken) return;
    getTrips()
      .then((res) => {
        setTripLength(res.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tripToken]);

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      accessToken:
        "pk.eyJ1Ijoic29saXNoIiwiYSI6ImNsZGExdmJ5bjBkemQzcHN6NzVhbXJidXcifQ.s8Crrxn4TRmtd8pZ5M3Sww",
      container: mapContainer.current,
      style:
        "https://api.parsimap.ir/styles/parsimap-streets-v11?key=p1c7661f1a3a684079872cbca20c1fb8477a83a92f",
      center: [lng, lat],
      zoom: zoom,
    });
  }, [map]);

  useEffect(() => {
    removePopup();
    if (typeof resultNumber !== "number") {
      setResultAccept(false);
      return;
    }
    document
      .getElementById("results")
      ?.scrollTo(0, 57 * (resultNumber - 1) + 44);
    popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    })
      .setLngLat([
        result[Math.floor(resultNumber)].lng,
        result[Math.floor(resultNumber)].lat,
      ])
      .setHTML(document.getElementById("popup").innerHTML)
      .addTo(map.current);
    document.getElementById("ResultAccept").addEventListener("click", () => {
      setResultAccept(true);
      setClosing(true);
    });
    document.getElementById("addToTrip").addEventListener("click", () => {
      setTripPlace([result[Math.floor(resultNumber)], Math.random()]);
      setLoading(true);
    });
  }, [resultNumber]);

  useEffect(() => {
    removePopup();
    if (place && place !== "") {
      map.current.flyTo({
        center: [place.lng, place.lat],
        zoom: 16.2 - Math.log2(distance * 2),
        duration: 4000,
        essential: true,
      });
      removeAllMarks();
      BestPointinlineData = [[place.lng, place.lat]];
      if (place !== lastPlace) {
        setBestPoints(distance);
        let marker = new mapboxgl.Marker({
          color: place.type
            ? ""
            : place?.type === "attraction"
            ? "#800080"
            : place.type === "facility"
            ? "#ffa500"
            : "#C59358",
        })
          .setLngLat([place.lng, place.lat])
          .addTo(map.current);
        currentMarkers = [marker];
      }
      setLastPlace(place);
    }
  }, [place, distance]);

  const setIranMarker = (res) => {
    removePointMarks();
    if (res[0]) {
      res.forEach((x, i) => {
        let marker = new mapboxgl.Marker({
          color:
            x?.type === "attraction"
              ? "#ffa500"
              : x.type === "facility"
              ? "#800080"
              : "",
          scale: 0.6,
        })
          .setLngLat([x.lng, x.lat])
          .addTo(map.current);
        marker.getElement().style.cursor = "pointer";
        marker.getElement().addEventListener("click", () => {
          setResultNumber(i);
        });
        pointMarkers.push(marker);
      });
      map.current.flyTo({
        center: [49.8995, 33.3171],
        zoom: 5.1,
        duration: 2000,
        essential: true,
      });
    }
  };

  const setBestPoints = (distance, fastestFilter) => {
    removePopup();
    removePopups();
    if (place && place !== "") {
      removeAllMarks();
    }
    let bestPoint = [...getbestPoints(BestPointinlineData, distance)];
    let weatherPoint = [...getbestPoints(BestPointinlineData, 100)];
    removePointMarks();
    if (weather && weatherPoint[0]) {
      getWeather(weatherPoint).then((res) => {
        let lastList = [];
        res.result.forEach((element) => {
          if (
            lastList.find(
              (x) =>
                Math.abs(x[0] - Number(element.lng)) < 0.42 &&
                Math.abs(x[1] - Number(element.lat)) < 0.42
            )
          )
            return;
          lastList.push([Number(element.lng), Number(element.lat)]);
          let el = document.createElement("div");
          el.style.backgroundColor = "#000000a0";
          el.style.backgroundImage = `url("${element.icon}")`;
          el.style.backgroundSize = `cover`;
          el.style.width = `30px`;
          el.style.height = `30px`;
          el.style.borderRadius = `7px`;
          el.style.zIndex = `1`;
          let marker = new mapboxgl.Marker(el)
            .setLngLat([element.lng, element.lat])
            .addTo(map.current);
          pointMarkers.push(marker);
        });
      });
    }
    if (bestPoint[0]) {
      setLoading(true);
      getRouteArea(
        bestPoint,
        distance,
        fastestFilter ? fastestFilter : filters
      ).then((res) => {
        let data = [];
        res.result.map((x) => {
          data.push(x);
        });
        setResult(data);
        for (let m = 0; m < res.result.length; m++) {
          if (res.result[m].lat) {
            let marker = new mapboxgl.Marker({
              color:
                res.result[m]?.type === "attraction"
                  ? "#ffa500"
                  : res.result[m].type === "facility"
                  ? "#800080"
                  : "",
              scale: 0.6,
            })
              .setLngLat([res.result[m].lng, res.result[m].lat])
              .addTo(map.current);
            marker.getElement().style.cursor = "pointer";
            marker.getElement().addEventListener("click", () => {
              setResultNumber(m);
            });
            pointMarkers.push(marker);
          }
        }
        setLoading(false);
      });
    } else {
      toast.warning("لطفا مکان خود را انتخاب کنید");
    }
  };

  const setPointAgain = (data) => {
    for (let m = 0; m < data.length; m++) {
      if (data[m].lat) {
        let marker = new mapboxgl.Marker({
          color:
            data[m]?.type === "attraction"
              ? "#ffa500"
              : data[m].type === "facility"
              ? "#800080"
              : "",
          scale: 0.6,
        })
          .setLngLat([data[m].lng, data[m].lat])
          .addTo(map.current);
        marker.getElement().style.cursor = "pointer";
        marker.getElement().addEventListener("click", () => {
          setResultNumber(m);
        });
        pointMarkers.push(marker);
      }
    }
  };

  const removePointMarks = () => {
    pointMarkers.forEach((marker) => marker.remove());
  };

  const removeAllMarks = () => {
    currentMarkers.forEach((marker) => marker.remove());
    currentMarkers = [];
    pointMarkers.forEach((marker) => marker.remove());
  };

  const removeAllLine = () => {
    for (let i = 0; i <= layerIndex.length - 1; i++) {
      map.current.removeLayer(`route${i}`);
      map.current.removeSource(`route${i}`);
    }
    layerIndex = [];
  };

  useEffect(() => {
    if (searchValue.length > 0) {
      search(searchValue)
        .then((res) => setSearchData(res))
        .catch((err) => console.log(err));
    } else {
      setSearchData({});
    }
  }, [searchValue]);

  const removePopups = () => {
    popups.forEach((x) => {
      x.remove();
    });
  };
  useEffect(() => {
    removePopups();
    durationsAndDestance.forEach((x) => {
      popups.push(
        new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat([x.latlng[0], x.latlng[1]])
          .setHTML(document.getElementById(`wayPopup${x.key}`).innerHTML)
          .addTo(map.current)
      );
    });
  }, [durationsAndDestance]);

  useEffect(() => {
    setResult([]);
    setResultAccept(false);
    setResultNumber(null);
    removeAllMarks();
    removePopup();
    let minCenterlat = destinations[0].lat;
    let maxCenterlat = destinations[0].lat;
    let minCenterlng = destinations[0].lng;
    let maxCenterlng = destinations[0].lng;
    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i].lat) {
        let marker = new mapboxgl.Marker({
          color:
            destinations[i]?.type === "attraction"
              ? "#800080"
              : destinations[i].type === "facility"
              ? "#ffa500"
              : "",
        })
          .setLngLat([destinations[i].lng, destinations[i].lat])
          .addTo(map.current);
        currentMarkers.push(marker);
      }
      if (minCenterlat > destinations[i].lat) {
        minCenterlat = destinations[i].lat;
      }
      if (maxCenterlat < destinations[i].lat) {
        maxCenterlat = destinations[i].lat;
      }
      if (minCenterlng > destinations[i].lng) {
        minCenterlng = destinations[i].lng;
      }
      if (maxCenterlng < destinations[i].lng) {
        maxCenterlng = destinations[i].lng;
      }
    }
    if (
      (destinations[0].lat && destinations[1].lat) ||
      (destinations[2]?.lat && destinations[1].lat)
    ) {
      map.current.flyTo({
        center: [
          (minCenterlng + maxCenterlng) / 2,
          (minCenterlat + maxCenterlat) / 2,
        ],
        zoom:
          15.7 -
          Math.log2(
            distancing(
              [minCenterlng, minCenterlat],
              [maxCenterlng, maxCenterlat]
            ) / 1000
          ),
        duration: 2000,
        essential: true,
      });
    }
    removeAllLine();
    BestPointinlineData = [];
    for (let i = 0; i < destinations.length - 1; i++) {
      if (destinations[i].lat && destinations[i + 1].lat) {
        GetRoute(
          [destinations[i].lng, destinations[i].lat],
          [destinations[i + 1].lng, destinations[i + 1].lat]
        )
          .then((res) => {
            let newData = [];
            res?.routes[0]?.legs[0]?.steps?.forEach((item) => {
              let plo = polyline.toGeoJSON(item.polyline.points);
              newData.push(...plo);
            });
            setDurationsAndDestance((x) => {
              if (x.filter((item) => item.key === i)[0]) {
                return x;
              } else {
                return [
                  ...x,
                  {
                    key: i,
                    distance: res?.routes[0]?.distance,
                    duration: res?.routes[0]?.duration,
                    latlng: newData[Math.floor(newData.length / 2)],
                  },
                ];
              }
            });
            map.current.addSource(`route${i}`, {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: newData,
                },
              },
            });
            map.current.addLayer({
              id: `route${i}`,
              type: "line",
              source: `route${i}`,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": `${destinations[i + 1].color}`,
                "line-width": 5,
              },
            });
            layerIndex.push({});
            BestPointinlineData = [...BestPointinlineData, ...newData];
            if (i === destinations.length - 2) {
              let weatherPoint = [...getbestPoints(BestPointinlineData, 100)];
              if (weather && weatherPoint[0]) {
                getWeather(weatherPoint).then((res) => {
                  let lastList = [];
                  res.result.forEach((element) => {
                    if (
                      lastList.find(
                        (x) =>
                          Math.abs(x[0] - Number(element.lng)) < 0.42 &&
                          Math.abs(x[1] - Number(element.lat)) < 0.42
                      )
                    )
                      return;
                    lastList.push([Number(element.lng), Number(element.lat)]);
                    let el = document.createElement("div");
                    el.style.backgroundColor = "#000000a0";
                    el.style.backgroundImage = `url("${element.icon}")`;
                    el.style.backgroundSize = `cover`;
                    el.style.width = `30px`;
                    el.style.height = `30px`;
                    el.style.borderRadius = `7px`;
                    el.style.zIndex = `1`;
                    let marker = new mapboxgl.Marker(el)
                      .setLngLat([element.lng, element.lat])
                      .addTo(map.current);
                    pointMarkers.push(marker);
                  });
                });
              }
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, [destinations]);

  return (
    <div>
      <ToastContainer position="top-right" theme="colored" autoClose={3500} />
      {loading && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={20}
          bgcolor="#000000c7"
          top="0"
          bottom="0"
          right="0"
          left="0"
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}
      <Header
        setTripId={setTripId}
        map={map}
        onClick={() => setShowMyTrip(true)}
        setTrip={setTrip}
        trip={trip}
        tripLength={tripLength}
        setShowMyTrip={setShowMyTrip}
        setTripLength={setTripLength}
      />
      <Box sx={{ transition: "3s all" }}>
        <Box
          position="fixed"
          zIndex={10}
          left={hidden === true ? "-50px" : "295px"}
          top="135px"
          maxHeight="calc(100vh - 70px)"
          display={"flex"}
          sx={{ transform: "rotate(90deg)", transition: "0.5s all" }}
        >
          {tablet && !phone && (
            <Box>
              <ShowNab
                text={" نمایش پنل جستجو"}
                onClick={() => setHidden(!hidden)}
                open={!hidden}
              />
            </Box>
          )}
        </Box>
        <Box>
          {!showMyTrip && (
            <MapFilters
              place={place}
              closing={closing}
              setClosing={setClosing}
              ref1={ref1}
              ref2={ref2}
              ref3={ref3}
              ref4={ref4}
              ref5={ref5}
              ref6={ref6}
              ref7={ref7}
              ref8={ref8}
              ref9={ref9}
              ref10={ref10}
              ref11={ref11}
              ref12={ref12}
              setIranMarker={setIranMarker}
              way={way}
              setWay={setWay}
              width={hidden !== true ? "325px" : tablet ? "0px" : "325px"}
              px={hidden !== true ? "20px" : tablet ? "0px" : "30px"}
              setLoading={setLoading}
              removeAllLine={removeAllLine}
              setDestinations={setDestinations}
              destinations={destinations}
              removeAllMarks={removeAllMarks}
              removePointMarks={removePointMarks}
              setSearch={setSearch}
              searchData={searchData}
              setSearchArray={setSearchArray}
              searchDataArray={searchValueArray}
              setPlace={setPlace}
              changeBestPoint={(x) => (BestPointinlineData = x)}
              setBestPoints={setBestPoints}
              result={result}
              setResultNumber={setResultNumber}
              distance={distance}
              setDistance={setDistance}
              setFilters={setFilters}
              resultNumber={
                resultNumber ? Math.floor(resultNumber) : resultNumber
              }
              resultAccept={resultAccept}
              setResultAccept={setResultAccept}
              removePopup={removePopup}
              removePopups={removePopups}
              map={map}
              filters={filters}
              idPlace={resultNumber ? Math.floor(resultNumber) : resultNumber}
              onClick={setBestPoints}
              setResult={setResult}
              lastWayResult={lastWayResult}
              setLastWayResult={setLastWayResult}
              setPointAgain={setPointAgain}
              setTripPlace={setTripPlace}
            />
          )}
          {showMyTrip && (
            <MyTrip
              clickLayer={clickLayer}
              setClickLayer={setClickLayer}
              setTripPlaceAdded={setTripPlaceAdded}
              setTripId={setTripId}
              setShowMyTrip={setShowMyTrip}
              width={hidden !== true ? "350px" : tablet ? "0px" : "350px"}
              tripId={tripId}
              map={map}
              tripPlace={tripPlace[0]}
              showResult={showResult}
            />
          )}
        </Box>
      </Box>
      <div ref={mapContainer} className="map-container" />
      <Popup
        setResultNumber={setResultNumber}
        resultNumber={resultNumber ? Math.floor(resultNumber) : resultNumber}
        result={result}
      />
      <TripDialog
        trip={trip}
        setTrip={setTrip}
        setTripId={setTripId}
        tripPlace={tripPlace}
        setShowMyTrip={setShowMyTrip}
      />
      {phone && (
        <>
          <Box position={"fixed"} bottom={"0px"} width={"100%"} zIndex={"10"}>
            {/* <CardLocation /> */}
            <MenuPhone
              info={info}
              setInfo={setInfo}
              setClickLayer={() => setClickLayer(true)}
              map={map}
              showMyTrip={showMyTrip}
              setShowMyTrip={setShowMyTrip}
              showResult={showResult}
              setTripId={setTripId}
              setTripLength={setTripLength}
              onClickWay={() => setWay(true)}
              onClickPoints={() => setWay(false)}
              onClickMyTravel={() => setTrip(3)}
              onClickLogin={() => setTrip(1)}
              setShowResult={() => setShowResult(!showResult)}
            />
          </Box>
        </>
      )}
      {searchEreaButton && (
        <Button
          onClick={() => {
            setSearchErea(map.current.getCenter());
            setPlace(map.current.getCenter());
          }}
          sx={{
            position: "fixed",
            bgcolor: "#C59358",
            color: "white",
            right: "50%",
            bottom: phone ? "70px" : "30px",
            zIndex: "10 !important",
            transform: "translateX(50%)",
            borderRadius: "10px",
            "&:hover": {
              bgcolor: "#C59358C0",
            },
          }}
        >
          جستجو در این مکان
        </Button>
      )}
      <Tooltip
        title={weather ? "حذف وضعیت آب و هوا" : "فعال کردن وضعیت آب و هوا"}
        arrow
        placement="top"
      >
        <IconButton
          onClick={() => {
            setWeather((x) => !x);
          }}
          sx={{
            position: "fixed",
            bgcolor: weather ? "#C59358" : "#ffffff",
            right: "10px",
            bottom: phone ? "70px" : "30px",
            zIndex: "10 !important",
            borderRadius: "10px",
            boxShadow: "0 3px 6px #00000025",
            "&:hover": {
              bgcolor: weather ? "#C59358C0" : "#ffffffc0",
            },
          }}
        >
          <WeatherIcon color={weather ? "#fff" : "#000"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="دانلود وب اپلیکیشن ویزیت ایران" arrow placement="top">
        <Box
          sx={{
            position: "fixed",
            bgcolor: "#C59358",
            right: "50px",
            bottom: phone ? "70px" : "30px",
            zIndex: "10 !important",
            borderRadius: "10px",
            "&:hover": {
              bgcolor: "#C59358C0",
            },
          }}
        >
          <AddToHomeScreenButton />
        </Box>
      </Tooltip>
      {!phone && (
        <Tooltip title="راهنما" arrow placement="top">
          <IconButton
            onClick={() => {
              setInfo(true);
            }}
            sx={{
              position: "fixed",
              bgcolor: info ? "#C59358" : "#ffffff",
              right: "90px",
              bottom: "30px",
              zIndex: "10 !important",
              borderRadius: "10px",
              boxShadow: "0 3px 6px #00000025",
              "&:hover": {
                bgcolor: info ? "#C59358C0" : "#ffffffc0",
              },
            }}
          >
            <GuideIcon color={info ? "#fff" : "#000"} width={"15"} />
          </IconButton>
        </Tooltip>
      )}
      {durationsAndDestance?.map((x) => {
        return <WayPopup x={x} key={x.key} />;
      })}
      <Dialog
        open={info}
        onClose={() => {
          setInfo(false);
          localStorage.setItem("infoSeen", "true");
        }}
        maxWidth="lg"
      >
        <InfoModal />
      </Dialog>
    </div>
  );
}

export default App;
