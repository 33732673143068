import { useState } from "react";

import { Button, TextField, Typography } from "@mui/material";

import { useEffect } from "react";
import { signUp } from "./api/trip";
import { setTripToken, getTripToken } from "./api/token";

import toEnglishNumber from "../toEnglishNumber";

export default function GetPhone({ setTrip, setMainPhone, setReminder }) {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);

  const t = true;

  useEffect(() => {
    if (!phone[10] && !error) return;
    if (
      phone[0] !== "0" ||
      phone[1] !== "9" ||
      phone.length !== 11 ||
      isNaN(Number(phone))
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [phone]);

  useEffect(() => {
    if (getTripToken()) {
      setTrip(3);
    }
  }, [t]);

  return (
    <>
      <Typography color="#8E8E8E" fontSize="12" pr="8px">
        لطفا شماره تلفن همراه خود را وارد نمایید
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          signUp({ phone: phone })
            .then((res) => {
              if (res.token) {
                setTripToken(res.token);
                setTrip(3);
              } else {
                setReminder(res.reminder);
                setMainPhone(phone);
                setTrip(2);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }}
      >
        <TextField
          error={error}
          onChange={(event) => setPhone(toEnglishNumber(event.target.value))}
          sx={{ width: "296px !important", pr: "14px" }}
          variant="standard"
          placeholder="تلفن همراه"
        />
        <Button
          disabled={error || !phone[10]}
          type="submit"
          // onClick={}
          variant="contained"
          sx={{
            bgcolor: "#C59358",
            color: "white",
            fontSize: "12px",
            position: "absolute",
            bottom: "25px",
            left: "20px",
            borderRadius: "10px",
            ":hover": {
              bgcolor: "#C59358d6",
            },
          }}
        >
          ادامه
        </Button>
      </form>
    </>
  );
}
