import { Box, useMediaQuery } from "@mui/material";

import FieldArrayBox from "./fieldArrayBox";

export default function FieldArrayFilter({
  destinations,
  setDestinations,
  display,
  ref1,
  ref2,
  ref3,
  ref4,
  ref5,
  ref6,
  ref7,
  ref8,
  ref9,
  ref10,
  ref11,
  ref12,
  hidden,
}) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <Box
      display={hidden ? "none" : display}
      mt={phone ? "8px" : "5px"}
      bgcolor="white"
      zIndex={1}
      width={phone ? "100%" : "calc(325px - 43px)"}
      p="16px 13px 30px 30px"
      borderRadius={phone ? "0px" : "10px"}
      boxShadow="0 3px 6px #00000025"
    >
      {destinations &&
        destinations.map((object, index) => {
          return (
            <FieldArrayBox
              ref1={ref1}
              ref2={ref2}
              ref3={ref3}
              ref4={ref4}
              ref5={ref5}
              ref6={ref6}
              ref7={ref7}
              ref8={ref8}
              ref9={ref9}
              ref10={ref10}
              ref11={ref11}
              ref12={ref12}
              key={object.key}
              object={object}
              index={index}
              setDestinations={setDestinations}
              destinations={destinations}
            />
          );
        })}
    </Box>
  );
}
