export default function Navigation({ color, width }) {
  let height = width ? width * (19.013 / 15.01) : 19.013;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? `${width}` : "15.01"}
      height={width ? `${height}` : "19.013"}
      viewBox={width ? `0 0 ${width} ${height}` : `0 0 15.01 19.013`}
      className="svg_transition"
    >
      <path
        id="Icon_material-navigation"
        data-name="Icon material-navigation"
        d="M14.255,3,6.75,21.3l.71.71,6.795-3,6.795,3,.71-.71Z"
        transform={`scale(${width ? Number(width) / 15.01 : 1},${
          height ? Number(height) / 19.013 : 1
        }) translate(-6.75 -3)`}
        fill={color ? color : "#e33156"}
      />
    </svg>
  );
}
