export const tokenKey = "map_trip_token";
export const key = "p147e61e68fa364653b4710fdecd3d6cf55e40b00d";

export const getTripToken = () => {
  const token = localStorage.getItem(tokenKey);
  return token;
};

export const setTripToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

export const removeTripToken = () => {
  localStorage.removeItem(tokenKey);
};
