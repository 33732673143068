import React from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Popover, TextField, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/system";

export default function DatePicker({
  input,
  label,
  valueInput,
  setValueInput,
  defValue,
  placeholder,
  name,
  minDate,
  maxDate,
  fullWidth,
  readOnly,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = useState();

  const phone = useMediaQuery("(max-width:520px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = "simple-popover";

  useEffect(() => {
    if (value !== undefined) {
      setValueInput(value?.year + "/" + value?.month + "/" + value?.day);
      handleClose();
    }
  }, [value]);
  return (
    <>
      {input && (
        <TextField
          name={name || ""}
          sx={{ mr: "10px" }}
          variant="standard"
          value={valueInput !== undefined ? valueInput : ""}
          placeholder={placeholder ? placeholder : "تاریخ شروع"}
          onClick={handleClick}
          fullWidth={fullWidth}
        />
      )}
      {label && (
        <Typography
          aria-describedby={id}
          onClick={!readOnly && handleClick}
          mr={"3px"}
          fontSize={"14px"}
          color={"#8E8E8E"}
          sx={{ cursor: !readOnly && "pointer" }}
        >
          {valueInput ? valueInput : "تاریخ بازدید"}
        </Typography>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: "fit-content",
            marginTop: phone ? "-110px" : "-60px",
            // bottom:'',
            right: phone ? "0px" : "",
            borderRadius: "10px",
            boxShadow: "0px 3px 6px -3px",
          },
        }}
      >
        <Calendar
          value={defValue ? defValue : value ? value : ""}
          onChange={setValue}
          calendar={persian}
          locale={persian_fa}
          minDate={minDate || new Date()}
          maxDate={maxDate || undefined}
        />
      </Popover>
    </>
  );
}
