import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import getApp from "../assets/get-app.svg";

const AddToHomeScreenButton = () => {
  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      setPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleAddToHomeScreenClick = () => {
    if (!prompt) return;
    prompt.prompt();

    prompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("The app was added to the home screen");
      } else {
        console.log("The app was not added to the home screen");
      }
    });
  };

  return (
    <IconButton
      onClick={() => handleAddToHomeScreenClick()}
      sx={{
        bgcolor: "#C59358",
        borderRadius: "10px",
        "&:hover": {
          bgcolor: "#C59358C0",
        },
        boxShadow: "0px 3px 6px #00000025",
      }}
    >
      <img src={getApp} alt="" />
    </IconButton>
  );
};

export default AddToHomeScreenButton;
