import { get, post } from ".";

export const RouteAreaToken = "BabCVTs119GneBqINF9b7jQrb7pFoWxEzWUrEo0k";

export const routeKey = "p147e61e68fa364653b4710fdecd3d6cf55e40b00d";

export const GetRoute = (first, second) => {
  return get(
    `https://api.parsimap.ir/direction/route?key=${routeKey}&waypoints=${first[0]},${first[1]}|${second[0]},${second[1]}&travel_mode=driving&steps=true`
  );
};

export const search = (value) => {
  return get(
    `https://www.visitiran.ir/api/map/search/placeMap?value=${value}&language=fa&types=destination,attraction,facility`
  );
};

export const getRouteArea = (data, distance, filters) => {
  let fd = new FormData();
  fd.append("language", "fa");
  fd.append("radius", distance);
  fd.append("_token", RouteAreaToken);
  for (let i = 0; i < data.length; i++) {
    fd.append(`points[${i}][lat]`, data[i][1]);
    fd.append(`points[${i}][lng]`, data[i][0]);
  }
  for (let i = 0; i < filters?.length; i++) {
    for (let j = 0; j < filters[i].length; j++) {
      fd.append("filters[]", filters[i][j]);
    }
  }
  return post(`https://www.visitiran.ir/api/map/search/inRouteArea`, fd);
};

export const getFilters = () => {
  return get("https://demo.visitiran.ir/api/map/search/fitlers?language=fa");
};

export const getPlaceArea = (filter) => {
  return get(
    `https://www.visitiran.ir/api/map/search/withFilter?language=fa&filter=${filter}`
  );
};

export const getWeather = (data) => {
  let str = "https://www.visitiran.ir/getMyMultiWeather?locations=";
  for (let i = 0; i < data.length; i++) {
    str = str + data[i][1] + "," + data[i][0];
    if (i !== data.length - 1) str = str + "|";
  }

  return get(
    str
    //   {
    //   credentials: true,
    //   withCredentials: true,
    //   defaults: { withCredentials: true },
    //   headers: {
    //     Cookie:
    //       "TOKEN=eyJpdiI6Ikx2OWxtS0dpUGR1V05SNWN6dW5Pa2c9PSIsInZhbHVlIjoiYVpDclZURWlNL2c2bGtscUxnR1lwZ0tyTE1EeXdDVGEyWHdiMDBpRnd1YW9kOGg4ckFjd3RpeVdPRGRmRjFXaFh4cWhha2dHa0RsNDg4YUtPbzJQaE5lQUt3TXV2bU5OQW5kZ1pTc0o3NlJiNkNjWFdnanU4R3dtckxxcnZ2YjMiLCJtYWMiOiIyOGQ1ZWVkOWYzNTVkODdiMjQwMWRiZDQ4NWVmZDZkMGI4NGM2MTA0ODlhMGFmMmQwYTZjNzhjZTJhNDhjNmE2In0%3D",
    //   },
    // }
  );
};
