import { Box, Typography } from "@mui/material";
import SelectBoxButton from "./selectBox";

import checked from "../assets/checked.svg";
import { useEffect, useState } from "react";

export default function RadioGroupBox({ array, onChange, Value }) {
  const [value, setValue] = useState(Value);

  useEffect(() => {
    setValue(Value);
  }, [Value]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <Box>
      {array.map((param, index) => {
        return (
          <SelectBoxButton
            key={index}
            setValue={setValue}
            value={param.value}
            selectedValue={value}
          >
            <Box display="flex" alignItems="center" my="2px">
              {value === param.value ? (
                <img src={checked} alt="check" />
              ) : (
                <Box border="1px solid #D9D9D9" width="7px" height="7px" />
              )}
              <Typography
                fontSize="12px"
                fontWeight="400"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="250px"
                color={value === param.value ? "#009BB9" : "#393939"}
                mr="8px"
              >
                {param.name}
              </Typography>
            </Box>
          </SelectBoxButton>
        );
      })}
    </Box>
  );
}
