import { Box, Typography } from "@mui/material";
import CheckBoxButton from "./checkBox";

import checked from "../assets/checked.svg";
import { useEffect, useState } from "react";

export default function CheckBoxGroup({
  array,
  onChange,
  Value,
  setAll,
}) {
  const [value, setValue] = useState(Value);

  useEffect(() => {
    setValue(Value);
  }, [Value]);

  useEffect(() => {
    onChange(value);
    if (value.length === array.length) {
      setAll(true);
    } else {
      setAll(false);
    }
  }, [value]);

  return (
    <Box>
      {/* <CheckBoxButton
        readOnly={true}
        setValue={setValue}
        value="1000000"
        selectedValues={value}
      >
        <Box
          display="flex"
          alignItems="center"
          my="2px"
          onClick={() => {
            if (value.length === array.length) {
              setValue([]);
            } else {
              setValue(array.map((x) => x.value));
            }
          }}
        >
          {value.length === array.length ? (
            <img src={checked} alt="check" />
          ) : (
            <Box border="1px solid #D9D9D9" width="7px" height="7px" />
          )}
          <Typography
            fontSize="12px"
            fontWeight="400"
            color={value.length === array.length ? "#009BB9" : "#393939"}
            mr="8px"
          >
            همه
          </Typography>
        </Box>
      </CheckBoxButton> */}
      {array.map((param, index) => {
        return (
          <CheckBoxButton
            key={index}
            setValue={setValue}
            value={param.value}
            selectedValues={value}
          >
            <Box display="flex" alignItems="center" my="2px">
              {value.find((x) => x === param.value) !== undefined ? (
                <img src={checked} alt="check" />
              ) : (
                <Box border="1px solid #D9D9D9" width="7px" height="7px" />
              )}
              <Typography
                fontSize="12px"
                fontWeight="400"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width="250px"
                color={
                  value.find((x) => x === param.value) !== undefined
                    ? "#009BB9"
                    : "#393939"
                }
                mr="8px"
              >
                {param.name}
              </Typography>
            </Box>
          </CheckBoxButton>
        );
      })}
    </Box>
  );
}
