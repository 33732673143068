export default function AccIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14.117"
      height="7.551"
      viewBox="0 0 14.117 7.551"
      style={{ transition: "all 0.4s" }}
    >
      <path
        id="Icon_metro-chevron-thin-left"
        data-name="Icon metro-chevron-thin-left"
        d="M17.5,16.848a.572.572,0,0,1,0,.8.558.558,0,0,1-.8,0l-6.426-6.49a.572.572,0,0,1,0-.8l6.426-6.49a.559.559,0,0,1,.8,0,.572.572,0,0,1,0,.8L11.64,10.76,17.5,16.848Z"
        transform="translate(-3.701 17.666) rotate(-90)"
        fill={props.color ? props.color : "#8e8e8e"}
      />
    </svg>
  );
}
