import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import ReactList from "react-list";
import {} from "react-list";
import LazyLoading from "react-list-lazy-load";

import Baggage from "../assets/baggage";
import Marker from "../assets/marker";

import logo from "../assets/logo.svg";
import ResultPicture from "./resultPicture";
import Search from "../assets/search";
import { useState } from "react";
import AccIcon from "../assets/accIcon";
import useInfiniteScroll from "react-easy-infinite-scroll-hook";
import { useEffect } from "react";

export default function ShowResult({
  data,
  setResultNumber,
  setResultAccept,
  resultNumber,
  onClickHidden,
  setTripPlace,
  setLoading,
  map,
  setHidden,
  hidden,
  setClosing,
}) {
  const phone = useMediaQuery("(max-width:520px)");
  const [showList, setShowList] = useState(false);
  const [number, setNumber] = useState(5);
  const [hiddenResult, setHiddenResult] = useState(false);

  useEffect(() => {
    if (!data[0]) return;
    let x = 0;
    const myTimeout = setTimeout(() => {
      x === 1 ? clearTimeout(myTimeout) : setNumber((prev) => prev + 5);
      x++;
    }, 200);
  }, [data]);

  const next = () => {
    setNumber(number + 10 > data.length ? data.length : number + 10);
  };

  const ref = useInfiniteScroll({
    next,
    rowCount: number,
    hasMore: { right: true },
  });

  useEffect(() => {
    if (hidden === false) setHiddenResult(false);
  }, [hidden]);

  return (
    data[0] && (
      <Box
        id="results"
        width={phone ? "100%" : "325px"}
        height={phone ? "100px" : "450px"}
        flex="auto"
        mt="5px"
        borderRadius="10px"
        overflow={phone && !showList ? "hidden" : "auto"}
        boxShadow={phone ? "0" : "0 3px 6px #00000025"}
        p="10px 0px"
        bgcolor={phone ? "" : "white"}
        sx={
          phone
            ? {
                display: hiddenResult ? "none" : "flex",
                position: "fixed",
                zIndex: "100",
                bottom: showList ? "" : "33px",
                marginTop: showList ? "-170px" : "0px",
                backgroundColor: showList ? "white" : "",
                height: showList ? "100vh" : "100px",
              }
            : {}
        }
      >
        {!phone && (
          <>
            <Typography color="#8E8E8E" px="15px">
              نتایج
            </Typography>
            <Box
              bgcolor="#B4B4B4"
              height="1px"
              width="calc(100% - 30px)"
              mt="9px"
              mx="15px"
            />
          </>
        )}
        {phone && (
          // <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Typography
            position={"fixed"}
            bgcolor={"white"}
            width={showList ? "20px" : "80px"}
            left={"10px"}
            marginTop={!showList ? "-25px" : "-25px"}
            // marginBottom={"45px"}
            borderRadius={"10px"}
            padding={"5px"}
            marginLeft={"5px"}
            fontSize={"12px"}
            color={"#8E8E8E"}
            textAlign={"center"}
            onClick={() => setShowList(!showList)}
          >
            {!showList && <Box>{" نمایش لیست "}</Box>}
            {showList && (
              <Box sx={{ transform: "rotate(90deg)", marginTop: "-11px" }}>
                <AccIcon />
              </Box>
            )}
          </Typography>
          // </Box>
        )}
        <Box
          marginTop={phone && hidden && showList ? "120px" : "0px"}
          width={phone && !showList ? "100vw" : "calc(100% - 15px)"}
          // display={showList ? "flex" : ""}
        >
          {phone && !showList ? (
            <Box display="flex" ref={ref} overflow="auto" width="100vw">
              {data.map((x, i) => {
                if (i <= number)
                  return (
                    <Box
                      key={i}
                      display={"flex"}
                      justifyContent="space-between"
                      alignItems="center"
                      width={"fit-content"}
                      borderBottom="0.5px solid #B4B4B4"
                      borderRadius={phone ? (showList ? "0px" : "10px") : "0px"}
                      marginRight={"10px"}
                      bgcolor="white"
                    >
                      <Box
                        width={!showList ? "100%" : "200px"}
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          width={phone ? (showList ? "40px" : "60px") : "40px"}
                          height={phone ? (showList ? "40px" : "60px") : "40px"}
                          overflow="hidden"
                          borderRadius="5px"
                          m="8px"
                        >
                          <ResultPicture logo={logo} param={data[i]} />
                        </Box>
                        <Box alignItems="center" display="flex">
                          <Typography
                            color="#8E8E8E"
                            fontSize="14px"
                            sx={{
                              whiteSpace: "nowrap",
                              maxWidth: "80px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data[i]?.name}
                          </Typography>
                          <Box height="fit-content" display="flex">
                            <Box
                              my="2px"
                              height="12px"
                              bgcolor="#B4B4B4"
                              width="0.5px"
                              mx="4px"
                            />
                            <Typography
                              color="#B4B4B4"
                              fontSize="10px"
                              sx={{
                                whiteSpace: "nowrap",
                                width: "50px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data[i]?.type === "attraction"
                                ? "جاذبه"
                                : data[i].type === "facility"
                                ? data[i].slogan || "تاسیسات"
                                : "مکان"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box width="fit-constent" height="fit-content">
                          <Tooltip title="افزودن به سفر" arrow placement="top">
                            <IconButton
                              onClick={() => {
                                setTripPlace([data[i], , Math.random()]);
                                setLoading(true);
                              }}
                            >
                              <Baggage color="#8E8E8E" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box width="fit-constent" height="fit-content">
                          <Tooltip title="نمایش در نقشه" arrow placement="top">
                            <IconButton
                              onClick={() => {
                                setHidden();
                                setResultNumber(i);
                                map.current.flyTo({
                                  center: [data[i].lng, data[i].lat],
                                  duration: 1,
                                  essential: true,
                                });
                              }}
                            >
                              <Marker
                                color={
                                  resultNumber === i ? "#C59358" : "#8E8E8E"
                                }
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box width="fit-constent" height="fit-content">
                          <Tooltip title="نمایش جزئیات" arrow placement="top">
                            <IconButton
                              sx={{ transform: "rotate(90deg)" }}
                              onClick={() => {
                                setResultNumber(i);
                                setResultAccept(true);
                                setClosing(true);
                                map.current.flyTo({
                                  center: [data[i].lng, data[i].lat],
                                  duration: 1,
                                  essential: true,
                                });
                              }}
                            >
                              <Search width="18.5" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  );
              })}
            </Box>
          ) : (
            <ReactList
              length={data.length}
              type="uniform"
              itemRenderer={(i, key) => {
                return (
                  <Box
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        bgcolor: resultNumber === i ? "#ffc80050" : "#f1f3f4",
                      },
                    }}
                    onClick={() => {
                      setHidden();
                      setResultNumber(i);
                      map.current.flyTo({
                        center: [data[i].lng, data[i].lat],
                        duration: 1,
                        essential: true,
                      });
                    }}
                    key={i}
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems="center"
                    width={"calc(100% - 15px)"}
                    borderBottom="0.5px solid #B4B4B4"
                    borderRadius={"0px"}
                    marginRight={"15px"}
                    bgcolor={resultNumber === i ? "#ffc80050" : "white"}
                  >
                    <Box
                      width={!showList ? "100%" : "200px"}
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        width={"40px"}
                        height={"40px"}
                        overflow="hidden"
                        borderRadius="5px"
                        m="8px"
                      >
                        <ResultPicture logo={logo} param={data[i]} />
                      </Box>
                      <Box alignItems="center" display="flex">
                        <Typography
                          color="#8E8E8E"
                          fontSize="14px"
                          sx={{
                            whiteSpace: "nowrap",
                            maxWidth: "80px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data[i]?.name}
                        </Typography>
                        <Box height="fit-content" display="flex">
                          <Box
                            my="2px"
                            height="12px"
                            bgcolor="#B4B4B4"
                            width="0.5px"
                            mx="4px"
                          />
                          <Typography
                            color="#B4B4B4"
                            fontSize="10px"
                            sx={{
                              whiteSpace: "nowrap",
                              width: "50px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data[i]?.type === "attraction"
                              ? "جاذبه"
                              : data[i].type === "facility"
                              ? data[i].slogan || "تاسیسات"
                              : "مکان"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box width="fit-constent" height="fit-content">
                        <Tooltip title="افزودن به سفر" arrow placement="top">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setTripPlace([data[i], , Math.random()]);
                              setLoading(true);
                            }}
                          >
                            <Baggage color="#8E8E8E" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box width="fit-constent" height="fit-content">
                        <Tooltip title="نمایش در نقشه" arrow placement="top">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setHidden();
                              setResultNumber(i);
                              map.current.flyTo({
                                center: [data[i].lng, data[i].lat],
                                duration: 1,
                                essential: true,
                              });
                            }}
                          >
                            <Marker
                              color={resultNumber === i ? "#C59358" : "#8E8E8E"}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box width="fit-constent" height="fit-content">
                        <Tooltip title="نمایش جزئیات" arrow placement="top">
                          <IconButton
                            sx={{ transform: "rotate(90deg)" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setResultNumber(i);
                              setResultAccept(true);
                              setClosing(true);
                              map.current.flyTo({
                                center: [data[i].lng, data[i].lat],
                                duration: 1,
                                essential: true,
                              });
                            }}
                          >
                            <Search width="18.5" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            />
          )}
        </Box>
        {phone && (
          <Box display={showList ? "flex" : "none"}>
            <Typography
              position={"fixed"}
              left={"10px"}
              color={"#8E8E8E"}
              fontSize={"12px"}
              bottom={"70px"}
              width={"87px"}
              height={"26px"}
              bgcolor={"white"}
              textAlign={"center"}
              borderRadius={"10px"}
              boxShadow={"0px 1px 4px -1px"}
              display={"grid"}
              alignItems={"center"}
              onClick={() => {
                onClickHidden();
                setShowList(!showList);
              }}
            >
              {hiddenResult ? "نمایش پنل جستجو" : " نمایش نقشه "}
            </Typography>
          </Box>
        )}
      </Box>
    )
  );
}
