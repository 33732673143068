import { delete_, get, post } from ".";

export const signUp = (data) => {
  return post("signUp", false, data);
};

export const Activate = (data) => {
  return post("activate", false, data);
};

export const createTrip = (data) => {
  return post("trip", true, data);
};

export const getTrips = () => {
  return get("trip?lang=fa", true);
};

export const getTrip = (id) => {
  return get(`trip/${id}?lang=fa`, true);
};

export const updateTrip = (id, data) => {
  return post(`trip/${id}`, true, data);
};

export const addTripPlaces = (id, data) => {
  return post(`trip/${id}/place`, true, data);
};

export const deleteTrip = (id) => {
  return delete_(`trip/${id}`, true);
};

export const removePlace = (id) => {
  return delete_(`place/${id}`, true);
};

export const logout = () => {
  return post(`logout`, true);
};

export const updateBatch = (id, data) => {
  return post(`trip/${id}/updateBatch`, true, data);
};
