import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import OptionResult from "./optionResult";
import { useEffect } from "react";

export default function OptionResult2({
  data,
  setData,
  onChange,
  minDate,
  maxDate,
}) {
  let characters = data.map((x) => {
    return { ...x, place: { ...x.place, id: x.id } };
  });

  function handleOnDragEnd(result) {
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    onChange(items);
  }

  const handleGo = (index, place) => {
    let array = [...characters];
    let newFirstObject = array[index];
    if (place === "last") {
      onChange([...array.filter((x, i) => i !== index), newFirstObject]);
    }
    if (place === "first") {
      onChange([newFirstObject, ...array.filter((x, i) => i !== index)]);
    }
  };

  const deleteOption = (id) => {
    setData((x) => {
      return {
        ...x,
        data: { ...x.data, places: x.data.places.filter((y) => y.id !== id) },
      };
    });
  };

  const setAt = (id, value) => {
    if (!data) return;
    setData((x) => {
      return {
        ...x,
        data: {
          ...x.data,
          places: x.data.places.map((y) => {
            if (y.id === id) {
              return { ...y, at: value };
            } else {
              return y;
            }
          }),
        },
      };
    });
  };

  const setMinute = (id, value) => {
    if (!data) return;
    setData((x) => {
      return {
        ...x,
        data: {
          ...x.data,
          places: x.data.places.map((y) => {
            if (y.id === id) {
              return { ...y, duration: value };
            } else {
              return y;
            }
          }),
        },
      };
    });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div
            className="characters"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {characters.map((param, index) => {
              return (
                <Draggable
                  key={String(param.id)}
                  draggableId={String(param.id)}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <OptionResult
                        onMinuteChange={setMinute}
                        setAt={setAt}
                        deleteOption={deleteOption}
                        onClick={handleGo}
                        param={param}
                        index={index}
                        minDate={minDate}
                        maxDate={maxDate}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
