import {
  Box,
  Button,
  Link,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

import logo from "../assets/logo.png";
import Route from "../assets/route";
import comment from "../assets/comment.svg";
import showLine from "../assets/showLine.svg";
import { useEffect } from "react";
import Baggage from "../assets/baggage";
import Delete from "../assets/delete";
import AccIcon from "../assets/accIcon";
import SearchNearPoints from "./searchNearPoints";
import { Line } from "./mytripComponent/result";
import { getRouteArea } from "./mapApi/map";
import mapboxgl from "mapbox-gl";

let marker;
export default function ResultBox({
  result,
  removePointMarker,
  removePointMarks,
  pointMarkers,
  setResultAccept,
  setResult,
  lastWayResult,
  setLastWayResult,
  onChange,
  setResultNumber,
  setPointAgain,
  display,
  map,
  filters,
  setTripPlace,
  setLoading,
  setDestinations,
}) {
  const [src, setSrc] = useState(false);
  const [distance, setDistance] = useState(10);
  const [hide, setHide] = useState(false);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [zoom, setZoom] = useState();

  const phone = useMediaQuery("(max-width:520px)");

  useEffect(() => {
    setSrc(false);
  }, [result]);

  const setBestPoints = (D) => {
    removePointMarks();
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
    map.current.flyTo({
      center: [result.lng, result.lat],
      zoom: 16.2 - Math.log2(10 * 2),
      duration: 4000,
      essential: true,
    });
    getRouteArea([[result.lng, result.lat]], 5, filters).then((res) => {
      setResult((x) => {
        setLastWayResult(x);
        setHide(true);
        return res.result;
      });
      for (let m = 0; m < res.result.length; m++) {
        if (res.result[m].lat) {
          let marker = new mapboxgl.Marker({
            color:
              res.result[m]?.type === "attraction"
                ? "#ffa500"
                : res.result[m].type === "facility"
                ? "#800080"
                : "",
            scale: 0.6,
          })
            .setLngLat([res.result[m].lng, res.result[m].lat])
            .addTo(map.current);
          marker.getElement().style.cursor = "pointer";
          marker.getElement().addEventListener("click", () => {
            setResultNumber(m);
          });
          pointMarkers.push(marker);
        }
      }
    });
  };

  return (
    <Box
      width={phone ? "98vw" : "320px !important"}
      borderRadius="10px"
      mt="10px"
      overflow="hidden"
      zIndex={1}
      display="flex"
      boxShadow="0 3px 6px #00000025"
      bgcolor="white"
      p="3px"
      flexDirection="column"
      sx={phone ? { zIndex: "10", position: "fixed", top: "50px" } : {}}
    >
      <Box
        position="relative"
        width="100%"
        height="320px"
        borderRadius="10px"
        overflow="hidden"
      >
        <Box
          width="100%"
          height="320px"
          zIndex={2}
          sx={{
            position: "absolute",
            backgroundImage:
              "linear-gradient(180deg, rgba(255,255,255,0) 75%, rgba(22,22,22,1) 100%)",
          }}
        >
          {result?.bookingUrl && result?.bookingUrl !== "" && (
            <a href={result?.bookingUrl}>
              <Button
                sx={{
                  position: "absolute",
                  bgcolor: "#00000064",
                  color: "white",
                  top: "6px",
                  right: "6px",
                  fontSize: "12px",
                  ":hover": {
                    bgcolor: "#00000048",
                  },
                }}
              >
                رزرو کنید
              </Button>
            </a>
          )}
          <Box position="absolute" left="6px" top="6px">
            <Tooltip title="افزودن به سفر" arrow placement="top">
              <Button
                onClick={() => {
                  setTripPlace([result, Math.random()]);
                  setLoading(true);
                }}
                sx={{
                  bgcolor: "#00000064",
                  minWidth: "26px",
                  minHeight: "26px",
                  mx: "2px",
                  borderRadius: "50%",
                  ":hover": {
                    bgcolor: "#00000048",
                  },
                }}
              >
                <Baggage />
              </Button>
            </Tooltip>
            <Button
              sx={{
                bgcolor: "#00000064",
                minWidth: "31px",
                minHeight: "31px",
                mx: "2px",
                borderRadius: "50%",
                transform: "rotate(90deg)",
                ":hover": {
                  bgcolor: "#00000048",
                },
              }}
              onClick={() => {
                if (lastWayResult[0]) {
                  setResult(lastWayResult);
                  setPointAgain(lastWayResult);
                  map.current.flyTo({
                    center: [lng, lat],
                    zoom: zoom,
                    duration: 4000,
                    essential: true,
                  });
                }
                setLastWayResult([]);
                setHide(false);
                removePointMarker();
                setResultAccept(false);
                setResultNumber(null);
              }}
            >
              <AccIcon color="#ffffff" />
            </Button>
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              color: "white",
              display: "flex",
              p: "5px",
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <Typography>{result?.name}</Typography>
            <Box border="0.25px solid #FFFFFF" height={"8px"}></Box>
            <Typography fontSize="12px">
              {result?.slogan || result?.tagsName[0] || result?.typeName}
            </Typography>
          </Box>
        </Box>
        {src ? (
          <Skeleton
            width="100%"
            height="100%"
            sx={{ transform: "scale(1 , 1)" }}
          ></Skeleton>
        ) : (
          <img
            width="100%"
            src={result?.pic}
            alt={result?.slogan || result?.typeName}
            onError={() => setSrc(true)}
          />
        )}
      </Box>
      <Typography
        whiteSpace="pre-wrap"
        width="calc(100% - 14px)"
        fontSize="12px"
        color="#8E8E8E"
        borderBottom="1px solid #C1C1C1"
        mx="7px"
        py="10px"
      >
        {src && "تصویر باگذاری نشده است/وزارت میراث فرهنگی\n"}
        {result?.summery ? result?.summery : ""}
      </Typography>
      <Box margin={"5px 10px"}>
        {!hide && (
          <SearchNearPoints
            setResultNumber={setResultNumber}
            display={display}
            onChange={onChange}
            setDistance={setDistance}
            distance={distance}
            inPost={true}
            setBestPoints={setBestPoints}
          />
        )}
      </Box>
      {!hide && (
        <Box margin={"0px 7px"}>
          <Line bgcolor={"#C1C1C1"} />
        </Box>
      )}

      <Box display="flex" alignItems="center" p="8px">
        <Button
          sx={{
            fontSize: "12px",
            width: "110%",
            borderLeft: "1px solid #B4B4B4",
            borderRadius: "0",
            color: "#C59358",
            justifyContent: "space-around",
            whiteSpace: "nowrap",
          }}
        >
          <Route color="#C59358" />
          کاوش در مسیر
        </Button>
        <Link target="_blank" href={result?.url}>
          <Button
            sx={{
              justifyContent: "space-around",
              fontSize: "12px",
              width: "95%",
              borderLeft: "1px solid #B4B4B4",
              borderRadius: "0",
              color: "#B4B4B4",
            }}
          >
            <img src={comment} alt="" />
            توضیحات
          </Button>
        </Link>
        <Link
          target="_blank"
          href={`https://www.google.com/maps/dir/?api=1&destination=${result?.lat},${result?.lng}`}
        >
          <Button
            sx={{
              fontSize: "12px",
              width: "95%",
              color: "#009BB9",
              justifyContent: "space-around",
            }}
          >
            <img src={showLine} alt="" />
            مسیر یابی
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
