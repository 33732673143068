import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import AccIcon from "../assets/accIcon";

export default function ShowNab({ open, onClick, text }) {
  const phone = useMediaQuery("(max-width:520px)");

  return (
    <>
      <Box sx={{ display: "flex", direction: "ltr" }}>
        <Box
          bgcolor={"white"}
          borderRadius={"20px"}
          width={"30px"}
          height={"30px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            transform: open === false ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "0.5s all",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <AccIcon />
        </Box>

        <Box width={phone ? "140px" : "112px"} height={"30px"} display={"flex"}>
          <Box
            onClick={onClick}
            borderRadius={"20px"}
            bgcolor={"white"}
            width={phone ? "200px" : "112px"}
            height={"30px"}
            alignItems={"center"}
            justifyContent={"center"}
            color={"#8E8E8E"}
            fontSize={"12px"}
            marginLeft={"5px"}
            style={{
              display: open === false ? "flex" : "none",
              cursor: "pointer",
            }}
          >
            {text}
          </Box>
        </Box>
      </Box>
    </>
  );
}
