import { apiAgent, apiAgentWithToken } from "./axios";

export async function get(path, token, config) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error?.response, error?.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).get(
      path,
      config
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function delete_(path, token, params, data) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).delete(path, {
      params,
      data,
    });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function post(path, token, data, config) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).post(
      path,
      data,
      config
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export async function patch(path, token, data, config) {
  const onSuccess = (response) => {
    return response.data;
  };

  const onError = (error) => {
    return Promise.reject(error.response, error.message);
  };

  try {
    const response = await (token ? apiAgentWithToken : apiAgent).patch(
      path,
      data,
      config
    );
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}
