import Axios from "axios";
import { getTripToken, removeTripToken } from "./token";

export const BaseUrl = "https://coin4.echeck.ir/api/";

export const apiAgent = Axios.create({ baseURL: BaseUrl });
export const apiAgentWithToken = Axios.create({ baseURL: BaseUrl });

apiAgent.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiAgent.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

apiAgentWithToken.interceptors.request.use(
  (config) => {
    const token = getTripToken();

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    if (error) {
      console.log(error)
      // removeTripToken()
    }
    return Promise.reject(error);
  }
);

apiAgentWithToken.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
