import { Box, Typography } from "@mui/material";

export default function Slide({ label, pic, description, tablet, phone }) {
  return (
    <Box width="100%">
      <Box
        m={phone ? "15px" : "50px"}
        display="flex"
        flexDirection={tablet ? "column" : "row"}
        minHeight="300px"
        height={tablet ? "650px" : ""}
      >
        <Box width="100%" height="100%">
          <Typography variant="h5" pb="20px">
            {label}
          </Typography>
          <Box height="70%" display="flex" flexDirection="column" justifyContent="space-evenly">
            {description}
          </Box>
        </Box>
        <Box
          width={tablet ? "auto" : "100%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img width={phone ? "300px" : "400px"} src={pic} alt="" />
        </Box>
      </Box>
    </Box>
  );
}
