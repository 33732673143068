import {
  Box,
  Button,
  Collapse,
  Input,
  InputBase,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import Search from "../../assets/search";
import Settings from "../../assets/settings";
import { deleteTrip, getTrip, updateTrip } from "../createTripDialog/api/trip";
import { Dialog } from "@mui/material";

// import setting from "../../assets/settings.svg";
import { Line } from "./result";

export default function SearchName({
  data,
  tripId,
  setShowMyTrip,
  removeLayer,
  removeMarkers,
  showSetting,
  // showChangeTrip,
  // setShowChangeTrip,
}) {
  const phone = useMediaQuery("(max-width:520px)");
  const [showPublic, setShowPublic] = useState(data?.data.url ? true : false);
  const [showChangeTrip, setShowChangeTrip] = useState(false);

  const [name, setName] = useState();
  const [url, setURL] = useState(data?.data.url);
  const [newName, setNewName] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (showSetting === true) {
      setShowChangeTrip(true);
    }
  }, [showSetting]);

  useEffect(() => {
    if (!showPublic) return;
    getTrip(tripId).then((res) => setURL(res.data.url));
  }, [showPublic]);
  return (
    <Box
      width={phone ? "calc(99vw-10px)" : "315px"}
      position="relative"
      mt={phone ? "3px" : "5px"}
      borderRadius={phone ? "0px" : "10px"}
      overflow="hidden"
      zIndex={1}
      bgcolor={"white"}
      boxShadow="0 3px 6px #00000025"
      alignItems={"center"}
      padding={"10px"}
    >
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        maxWidth="lg"
      >
        <Typography textAlign="center" pt="10px">
          اطمینان دارید ؟
        </Typography>
        <Box display="flex" p="10px" gap="10px">
          <Button
            onClick={() => {
              deleteTrip(tripId).then(() => {
                setOpenDeleteDialog(false);
                removeMarkers();
                removeLayer();
                setShowMyTrip(false);
              });
            }}
          >
            بله
          </Button>
          <Button color="error" onClick={() => setOpenDeleteDialog(false)}>
            خیر
          </Button>
        </Box>
      </Dialog>
      <Box display="flex" justifyContent="space-between">
        <Typography mr={"10px"}>{newName || data?.data.name}</Typography>
        <Box
          display="flex"
          gap="5px"
          alignItems={"center"}
          onClick={() => setShowChangeTrip(!showChangeTrip)}
          sx={{ cursor: "pointer" }}
        >
          <Typography color="#C5C5C5" fontSize="10px">
            تنظیمات
          </Typography>
          {/* <img src={setting} alt="setting" width="15px" /> */}

          <Settings
            transform={showChangeTrip ? "rotate(90deg)" : "rotate(180deg)"}
          />
        </Box>
      </Box>
      <Collapse in={showChangeTrip} timeout="auto">
        <Box m={"10px"}>
          <Line bgcolor={"#DEDEDE"} />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateTrip(tripId, { name: name }).then(() => {
                setNewName(name);
              });
            }}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <InputBase
                style={{
                  color: "black",
                  fontSize: "12px",
                  border: "none",
                  margin: "5px",
                }}
                placeholder={" تغییر نام "}
                onChange={(e) => setName(e.target.value)}
              />
              {name && (
                <Button
                  type="submit"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {" "}
                  تایید{" "}
                </Button>
              )}
            </Box>
          </form>

          <Line bgcolor={"#DEDEDE"} />
        </Box>
        <Box>
          {!showPublic && (
            <Box>
              <Button
                onClick={() => {
                  updateTrip(tripId, { is_public: 1 }).then(() =>
                    setShowPublic(true)
                  );
                }}
                sx={{ color: "black", fontSize: "12px" }}
              >
                {" تبدیل به سفر عمومی "}
              </Button>
              <Typography m={"0px 15px"} color={"#0089B7"} fontSize={"10px"}>
                {
                  " در صورتی که سفر عمومی باشد همه می توانند با استفاده از لینک سفر آن را مشاهده نمایند ولی قادر به تغییر آن نیستند "
                }
              </Typography>
            </Box>
          )}
          {showPublic && (
            <Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Button
                  onClick={() => {
                    updateTrip(tripId, { is_public: 0 }).then(() =>
                      setShowPublic(false)
                    );
                  }}
                  sx={{ color: "black", fontSize: "12px" }}
                >
                  {" تبدیل به سفر خصوصی "}
                </Button>
                {url && (
                  <Button
                    sx={{ color: "#C59358", fontSize: "10px" }}
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                    }}
                  >
                    {" کپی لینک سفر "}
                  </Button>
                )}
              </Box>
              <Typography m={"0px 15px"} color={"#0089B7"} fontSize={"10px"}>
                {
                  " در صورتی که سفر خصوصی باشد فقط شخص شما قادر به مشاهده وسفر خود هستید "
                }
              </Typography>
            </Box>
          )}
        </Box>
        <Box m={"5px 10px"}>
          <Line bgcolor={"#DEDEDE"} />
        </Box>
        <Button
          sx={{ color: "#E33156", fontSize: "12px" }}
          onClick={() => setOpenDeleteDialog(true)}
        >
          {" حذف سفر "}
        </Button>
        {/* <Box m={"5px 10px"}>
          <Line bgcolor={"#DEDEDE"} />
        </Box>
        <Box sx={{ direction: "ltr" }}>
          <Button
            sx={{ color: "#0066B1", fontSize: "12px", fontWeight: "bold" }}
          >
            {" تایید تغییرات"}
          </Button>
        </Box> */}
      </Collapse>
    </Box>
  );
}
