import { useState, useEffect } from "react";

import { Box, useMediaQuery } from "@mui/material";

import DetailsMyTrip from "./component/mytripComponent/detailsMyTrip";
import HeaderMyTrip from "./component/mytripComponent/headerMyTrip";
import Result from "./component/mytripComponent/result";
import SearchName from "./component/mytripComponent/searchName";
import mapboxgl from "mapbox-gl";

import { getTrip, updateTrip } from "./component/createTripDialog/api/trip";
import { GetRoute } from "./component/mapApi/map";
import { polyline } from "./component/polyline";
import WayPopup from "./component/wayPopup";
import { toast } from "react-toastify";

let startMarker;
let markers = [];
let layerIndex = [];
let popups = [];

export const removeBoth = (map) => {
  for (let i = 0; i <= layerIndex.length - 1; i++) {
    map.current.removeLayer(`route${i}`);
    map.current.removeSource(`route${i}`);
  }
  layerIndex = [];
  if (markers.length > 0) {
    markers.forEach((e) => e.remove());
    markers = [];
  }
  if (startMarker) {
    startMarker.remove();
  }
  popups.forEach((x) => {
    x.remove();
  });
};

export default function MyTrip({
  tripPlace,
  tripId,
  map,
  width,
  setShowMyTrip,
  setTripId,
  setTripPlaceAdded,
  showResult,
  clickLayer,
  setClickLayer,
}) {
  const [data, setData] = useState();
  const [start, setStart] = useState();
  const [startData, setStartData] = useState();
  const [durationsAndDestance, setDurationsAndDestance] = useState([]);
  const [getAgain, setGetAgain] = useState(false);
  const phone = useMediaQuery("(max-width:520px)");
  const [update, setUpdate] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(phone ? false : true);
  const [showSetting, setShowSetting] = useState(phone ? false : true);

  // const [showDatePicker, setShowDatePicker] = useState(phone ? false : true);

  const removeMarkers = () => {
    if (markers.length > 0) {
      markers.forEach((e) => e.remove());
      markers = [];
    }
    if (startMarker) {
      startMarker.remove();
    }
  };
  const removeLayer = () => {
    for (let i = 0; i <= layerIndex.length - 1; i++) {
      map.current.removeLayer(`route${i}`);
      map.current.removeSource(`route${i}`);
    }
    layerIndex = [];
  };

  useEffect(() => {
    if (clickLayer === true) {
      removeLayer();
      setDurationsAndDestance([]);
      setLayer();
      setClickLayer(false);
    }
  }, [clickLayer]);
  useEffect(() => {
    if (!start) return;

    if (startMarker) {
      startMarker.remove();
    }

    startMarker = new mapboxgl.Marker({ color: "#00b0ef" })
      .setLngLat([start.lng, start.lat])
      .addTo(map.current);
  }, [start]);

  useEffect(() => {
    if (!tripId) return;
    removeLayer();
    removeMarkers();
    setDurationsAndDestance([]);
    getTrip(tripId)
      .then((res) => {
        setData(res);
        if (res.data?.start_place?.name) setStart(res.data.start_place);
      })
      .catch((error) => console.log(error));
  }, [tripId]);

  useEffect(() => {
    if (markers.length > 0) {
      markers.forEach((e) => e.remove());
      markers = [];
    }
    if (data?.data?.places[0]) {
      data?.data?.places?.forEach((e) => {
        markers.push(
          new mapboxgl.Marker({ color: "#00b0ef" })
            .setLngLat([e.place.lng, e.place.lat])
            .addTo(map.current)
        );
      });
    }
  }, [data]);

  const save = () => {
    setUpdate(true);
    if (startData) {
      updateTrip(tripId, startData)
        .then(
          () => toast.success("مبدا و تاریخ شروع و اتمام با موفقیت ذخیره شد"),
          { autoClose: 6000 }
        )
        .catch(
          () => toast.error("ثبت مبدا و تاریخ شروع و اتمام با مشکل مواجه شد"),
          { autoClose: 6000 }
        );
    }
  };

  const disablingWay = () => {
    let date = false;
    data.data.places.forEach((e) => {
      if (!e.at || e.at === "") {
        date = true;
      }
    });
    return date;
  };

  const setLayer = () => {
    let colors = [{ at: "", color: "#009BB9" }];
    if (!start?.lat) toast.warning("لطفا مبدا را مشخص کنید")
    if (start?.lat && data?.data.places[0]) {
      for (let i = 0; i < data.data.places.length; i++) {
        if (i === 0) {
          colors[0].at = data.data.places[0].at;
        } else if (data.data.places[i].at !== colors[colors.length - 1].at) {
          colors.push({
            at: data.data.places[i].at,
            color: `rgb(${Math.floor(Math.random() * 150)}, ${Math.floor(
              Math.random() * 150
            )}, ${Math.floor(Math.random() * 150)})`,
          });
        }
        GetRoute(
          i === 0
            ? [start.lng, start.lat]
            : [
                data.data.places[i - 1].place.lng,
                data.data.places[i - 1].place.lat,
              ],
          [data.data.places[i].place.lng, data.data.places[i].place.lat]
        ).then((res) => {
          let newData = [];
          res?.routes[0]?.legs[0]?.steps?.forEach((item) => {
            let plo = polyline.toGeoJSON(item.polyline.points);
            newData.push(...plo);
          });
          setDurationsAndDestance((x) => {
            if (x.filter((item) => item.key === i)[0]) {
              return x;
            } else {
              return [
                ...x,
                {
                  key: i,
                  at: data.data.places[i].at,
                  distance: res?.routes[0]?.distance,
                  duration: res?.routes[0]?.duration,
                  latlng: newData[Math.floor(newData.length / 2)],
                },
              ];
            }
          });
          map.current.addSource(`route${i}`, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: newData,
              },
            },
          });
          map.current.addLayer({
            id: `route${i}`,
            type: "line",
            source: `route${i}`,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": `${
                colors.filter((x) => x.at === data.data.places[i].at)[0].color
              }`,
              "line-width": 5,
            },
          });
          layerIndex.push({});
        });
      }
    }
  };

  const removePopups = () => {
    popups.forEach((x) => {
      x.remove();
    });
  };
  useEffect(() => {
    removePopups();
    durationsAndDestance.forEach((x) => {
      popups.push(
        new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        })
          .setLngLat([x.latlng[0], x.latlng[1]])
          .setHTML(document.getElementById(`wayPopup${x.key}`).innerHTML)
          .addTo(map.current)
      );
    });
  }, [durationsAndDestance]);

  return (
    <Box
      position="fixed"
      left="0"
      mt={"8px"}
      zIndex={2}
      width={phone ? "100%" : width ? width : "350px"}
      height={"fit-content"}
      top="50px"
      bottom="0"
      bgcolor=""
      pb="20px"
      maxHeight={"calc(100vh - 70px)"}
      sx={{
        transition: "0.5s all",
        overflowX: "hidden",
        overflowY: "auto",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      {data?.data && (
        <>
          <HeaderMyTrip
            setTripId={setTripId}
            setTripPlaceAdded={setTripPlaceAdded}
            disablingWay={disablingWay()}
            setLayer={() => {
              removeLayer();
              setDurationsAndDestance([]);
              setLayer();
            }}
            setShowMyTrip={setShowMyTrip}
            save={save}
            removeMarkers={removeMarkers}
            removeLayer={removeLayer}
            removePopups={removePopups}
            setShowDatePicker={() => setShowDatePicker(!showDatePicker)}
            setShowSetting={() => {
              setShowSetting(!showSetting);
            }}
            showSetting={showSetting}
            showDatePicker={showDatePicker}
          />
          {showDatePicker && (
            <DetailsMyTrip
              data={data}
              setStart={setStart}
              setStartData={setStartData}
              removeLayer={() => {
                removeLayer();
                setDurationsAndDestance([]);
              }}
            />
          )}
          {showSetting && (
            <SearchName
              // showChangeTrip={showChangeTrip}
              // setShowChangeTrip={setShowChangeTrip}
              showSetting={showSetting}
              data={data}
              tripId={tripId}
              setShowMyTrip={setShowMyTrip}
              removeMarkers={removeMarkers}
              removeLayer={removeLayer}
            />
          )}
          {showResult && (
            <Result
              layer={layerIndex.length !== 0}
              minDate={startData?.start_at}
              maxDate={startData?.end_at}
              tripPlace={tripPlace}
              tripId={tripId}
              data={data}
              setData={setData}
              setGetAgain={setGetAgain}
              update={update}
              setUpdate={setUpdate}
              removeLayer={() => {
                removeLayer();
                setDurationsAndDestance([]);
              }}
            />
          )}
          {!phone &&
            durationsAndDestance?.map((x) => {
              return <WayPopup x={x} key={x.key} />;
            })}
          {phone && (
            <Box
              width={"100%"}
              // bgcolor={"white"}
              position="fixed"
              bottom={"65px"}
              display="flex"
              overflow={"auto"}
            >
              {durationsAndDestance
                .sort((x, y) => {
                  if (x.key > y.key) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                ?.map((x) => {
                  return <WayPopup x={x} key={x.key} />;
                })}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
