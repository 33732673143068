import React from "react";

export default function MoreIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="4.303"
      height="17.212"
      viewBox="0 0 4.303 17.212"
      cursor={"pointer"}
    >
      <path
        id="Icon_ionic-md-more"
        data-name="Icon ionic-md-more"
        d="M19.491,8.9a2.152,2.152,0,1,0-2.152,2.152A2.158,2.158,0,0,0,19.491,8.9Zm0,12.909a2.152,2.152,0,1,0-2.152,2.152A2.158,2.158,0,0,0,19.491,21.811Zm0-6.455a2.152,2.152,0,1,0-2.152,2.152A2.158,2.158,0,0,0,19.491,15.356Z"
        transform="translate(-15.187 -6.75)"
        fill="#b4b4b4"
      />
    </svg>
  );
}
