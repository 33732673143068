export default function Profile({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className="svg_transition"
    >
      <path
        id="Icon_material-person"
        data-name="Icon material-person"
        d="M13.5,13.5A3.75,3.75,0,1,0,9.75,9.75,3.749,3.749,0,0,0,13.5,13.5Zm0,1.875c-2.5,0-7.5,1.256-7.5,3.75V21H21V19.125C21,16.631,16,15.375,13.5,15.375Z"
        transform="translate(-6 -6)"
        fill={color ? color : "#fff"}
      />
    </svg>
  );
}
